import React, {useEffect} from 'react';

import AOS from "aos";
import Navbar from "../common/navbar";
import Footer from "../common/footer";
import Reach_us from "../common/reach_us";


function Digital_print() {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);


    return (
        <div className="bg-neutral-950">
            <Navbar/>
            <div className="container  mx-auto ">
                <section className=" pb-10">
                    <div className="relative h-[400px] overflow-hidden bg-cover bg-[50%] bg-no-repeat bg-[url('https://b-expression.com/wp-content/uploads/2019/12/qatar.jpg')]"></div>
                    <div className="container px-6 md:px-12">
                        <div className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,20%,0.3)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] ">


                            <section className="">
                                <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex ">
                                    <div className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl" data-aos="fade-up">

                                        <h2 className="text-5xl text-white font-extrabold md:text-5xl">
                                            Events & Exhibitions
                                        </h2>
                                        <p>
                                            Sed ut perspiciatis unde omnis iste natus voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae.
                                        </p>
                                        <div className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
                                            <a href="javascript:void(0)" className="block py-2 px-4 text-center text-white font-medium bg-indigo-600 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none">
                                                Contact Us
                                            </a>
                                            {/*<a href="javascript:void(0)" className="flex items-center justify-center gap-x-2 py-2 px-4 text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-lg md:inline-flex">*/}
                                            {/*    Get access*/}
                                            {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">*/}
                                            {/*        <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />*/}
                                            {/*    </svg>*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                    <div className="flex-none mt-14 md:mt-0 md:max-w-xl" data-aos="fade-left">
                                        <img
                                            src="https://images.unsplash.com/photo-1573164713619-24c711fe7878?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80"
                                            className=" md:rounded-tl-[108px]"
                                            alt=""
                                        />
                                    </div>
                                </div>

                            </section>

                        </div>
                    </div>
                </section>


                <section className="relative overflow-hidden py-28 px-4 bg-gray-900 md:px-8">
                    <div className="w-full h-full rounded-full bg-gradient-to-r from-[#58AEF1] to-pink-500 absolute -top-12 -right-14 blur-2xl opacity-10"></div>
                    <div className="max-w-xl mx-auto text-center relative">
                        <div className="py-4" data-aos="zoom-in">
                            <h3 className="text-3xl text-gray-200 font-semibold md:text-4xl">
                                You can type any Message Here
                            </h3>
                            <p className="text-gray-300 leading-relaxed mt-3">
                                Nam erat risus, sodales sit amet lobortis ut, finibus eget metus. Cras aliquam ante ut tortor posuere feugiat. Duis sodales nisi id porta lacinia.
                            </p>
                        </div>
                        {/*<div className="mt-5 items-center justify-center gap-3 sm:flex">*/}
                        {/*    <a*/}
                        {/*        href="javascript:void(0)"*/}
                        {/*        className="block w-full mt-2 py-2.5 px-8 text-gray-700 bg-white rounded-md duration-150 hover:bg-gray-100 sm:w-auto"*/}
                        {/*    >*/}
                        {/*        Try It Out*/}
                        {/*    </a>*/}
                        {/*    <a*/}
                        {/*        href="javascript:void(0)"*/}
                        {/*        className="block w-full mt-2 py-2.5 px-8 text-gray-300 bg-gray-700 rounded-md duration-150 hover:bg-gray-800 sm:w-auto"*/}
                        {/*    >*/}
                        {/*        Get Started*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                </section>



            </div>

            {/*1*/}
            <section className="container mx-auto">



                <div className="relative  ">
                    <div className="absolute inset-0 -z-1">
                        <div
                            className="absolute inset-0 bg-no-repeat bg-contain z-1 bg-left-bottom"
                            style={{ backgroundImage: `url('https://b-expression.com/wp-content/uploads/2019/12/ce.png')`, backgroundSize: 'cover' }}
                        ></div>
                    </div>
                    <div className="relative flex items-center m-auto w-full xl:max-w-screen-2xl lg:max-w-screen-xl md:max-w-screen-md lg:px-20 text-dark lg:gap-x-20">
                        {/*<div className="h-full">*/}
                        <div className="lg:w-1/2 w-full m-auto sm:py-11 py-1 lg:py-44 my-0 lg:mx-0 sm:my-0 visible z-30 my-8 lg:my-0 flex items-center justify-start px-6 md:px-12 lg:px-0 sm:max-w-screen-lg md:my-6 ml-auto bg-blue-500 bg-opacity-50 h-full flex flex-col-reverse rounded-tr-lg rounded-br-lg" data-aos="fade-right">
                            <div className="h-full">
                                <div className="p-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left" data-aos="zoom-in">
                                    <div className="z-10 w-full pb-4 md:pb-6">
                                        <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Corporate
                                            Event Services</h2>
                                        <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>
                                    </div>
                                    <div className="text-base sm:text-lg font-body sm:leading-loose">BrandX’s alented corporate event production and management team specializes in creating both large and intimate branded events of all kinds. Whether you
                                        are hosting your event locally, domestically or globally, we will produce your meeting, conference, awards ceremony and employee event at any scale with the highest level of detail you require. With full in-house art, scenic and digital studios, we offer both turnkey and custom builds using the most advanced technology available. Our craftsmen will construct unique items, complete branded environments or anything in between. We even have a full production
                                        team to meet your tech needs – from marketing video to run-of-show presentations.

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*</div>*/}
                    </div>

                </div>

                {/*  2*/}
                <div className="relative">
                    <div className="absolute inset-0 -z-1">
                        <div
                            className="absolute inset-0 bg-no-repeat bg-contain z-1 bg-left-bottom"
                            style={{ backgroundImage: `url('https://b-expression.com/wp-content/uploads/2019/12/sport-event.png')`, backgroundSize: 'cover' }}
                        ></div>
                    </div>

                    <div className="flex items-center justify-between m-auto w-full xl:max-w-screen-2xl lg:max-w-screen-xl md:max-w-screen-md lg:px-20 text-dark lg:gap-x-20">

                        <div className="lg:w-1/2 ">
                            {/*<div className="h-full">*/}
                            {/*    <div className="py-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left">*/}
                            {/*        <div className="z-10 w-full pb-4 md:pb-6">*/}
                            {/*            <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Modular Partitions</h2>*/}
                            {/*            <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-base sm:text-lg font-body sm:leading-loose">Modular partition systems provide unlimited design flexibility. Create walls, corners, booths, or complete rooms. Integrate lit and non-lit fabric panels as well as digital screens for an omnichannel display.</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        {/* Right Side Content */}
                        <div className="lg:w-1/2  m-auto sm:py-11 py-1 lg:py-44 my-0 lg:mx-0 sm:my-0 visible z-30 my-8 lg:my-0 flex items-center justify-start px-6 md:px-12 lg:px-0 sm:max-w-screen-lg md:my-6 mr-auto bg-red-500 bg-opacity-50 h-full flex flex-col-reverse rounded-tr-lg rounded-br-lg" data-aos="fade-left">
                            <div className="h-full">
                                <div className="p-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left"  data-aos="zoom-in">
                                    <div className="z-10 w-full pb-4 md:pb-6">
                                        <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Sporting Event Branding & Collateral</h2>
                                        <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>
                                    </div>
                                    <div className="text-base sm:text-lg font-body sm:leading-loose"> We offer event branding services perfectly catered to all sorts of environments and challenges.
                                        Offering creative and technical solutions that are both suited to indoor and outdoor situations. We offer solutions for award ceremonies, sport galas, press conferences, prize distribution ceremonies, opening and closing ceremonies. We also cover in-game branding for perimeter boards,
                                        billboards, fan merchandise and just about any other branding application you can think of!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/*3*/}

                <div className="relative  ">
                    <div className="absolute inset-0 -z-1">
                        <div
                            className="absolute inset-0 bg-no-repeat bg-contain z-1 bg-left-bottom"
                            style={{ backgroundImage: `url('https://b-expression.com/wp-content/uploads/2019/12/party-300x300.png')`, backgroundSize: 'cover' }}
                        ></div>
                    </div>
                    <div className="relative flex items-center m-auto w-full xl:max-w-screen-2xl lg:max-w-screen-xl md:max-w-screen-md lg:px-20 text-dark lg:gap-x-20">
                        {/*<div className="h-full">*/}
                        <div className="lg:w-1/2 w-full m-auto sm:py-11 py-1 lg:py-44 my-0 lg:mx-0 sm:my-0 visible z-30 my-8 lg:my-0 flex items-center justify-start px-6 md:px-12 lg:px-0 sm:max-w-screen-lg md:my-6 ml-auto bg-green-700 bg-opacity-50 h-full flex flex-col-reverse rounded-tr-lg rounded-br-lg" data-aos="fade-right">
                            <div className="h-full">
                                <div className="p-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left" data-aos="zoom-in">
                                    <div className="z-10 w-full pb-4 md:pb-6">
                                        <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Entertainment Event
                                            Production & Branding Services</h2>
                                        <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>
                                    </div>
                                    <div className="text-base sm:text-lg font-body sm:leading-loose">BrandX is a nationwide, all inclusive event production service. We take care of all your needs from entertainment and production to helping you with
                                        the planning and execution of your event. We do this to make your job is as easy as possible. At BrandX, we specialize in high energy events. We strive to create unique and memorable experiences for event attendees.
                                        Armed with the most talented and creative event designers in the country, we promise to create something remarkable that you and your guests will never forget. There are no limits to what we are capable of.

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*</div>*/}
                    </div>

                </div>

                {/*  4*/}
                <div className="relative">
                    <div className="absolute inset-0 -z-1">
                        <div
                            className="absolute inset-0 bg-no-repeat bg-contain z-1 bg-left-bottom"
                            style={{ backgroundImage: `url('https://b-expression.com/wp-content/uploads/2019/12/cer2.png')`, backgroundSize: 'cover' }}
                        ></div>
                    </div>

                    <div className="flex items-center justify-between m-auto w-full xl:max-w-screen-2xl lg:max-w-screen-xl md:max-w-screen-md lg:px-20 text-dark lg:gap-x-20">

                        <div className="lg:w-1/2 ">
                            {/*<div className="h-full">*/}
                            {/*    <div className="py-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left">*/}
                            {/*        <div className="z-10 w-full pb-4 md:pb-6">*/}
                            {/*            <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Modular Partitions</h2>*/}
                            {/*            <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>*/}
                            {/*        </div>*/}
                            {/*        <div className="text-base sm:text-lg font-body sm:leading-loose">Modular partition systems provide unlimited design flexibility. Create walls, corners, booths, or complete rooms. Integrate lit and non-lit fabric panels as well as digital screens for an omnichannel display.</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        {/* Right Side Content */}
                        <div className="lg:w-1/2  m-auto sm:py-11 py-1 lg:py-44 lg:mx-0 sm:my-0 visible z-30 my-8 lg:my-0 items-center justify-start px-6 md:px-12 lg:px-0 sm:max-w-screen-lg md:my-6 mr-auto bg-orange-600 bg-opacity-50 h-full flex flex-col-reverse rounded-tr-lg rounded-br-lg" data-aos="fade-left">
                            <div className="h-full">
                                <div className="p-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left"  data-aos="zoom-in">
                                    <div className="z-10 w-full pb-4 md:pb-6">
                                        <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Marketing Material Design, Print & Production Services</h2>
                                        <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>
                                    </div>
                                    <div className="text-base sm:text-lg font-body sm:leading-loose">With a comprehensive understanding of all national and religious events of the country and their processes, BrandX is the right organization for
                                        planning your next ceremonial event. From Independence Day celebrations to Eid Festivals,
                                        our team has been involved in all such events hosted in Qatar.
                                        From art direction to technical execution, we ensure a fantastic and streamlined experience for the visitors of your next event.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Reach_us/>

            <Footer/>

        </div>
    );
}

export default Digital_print;
