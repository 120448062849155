// Breadcrumb.js
import React from 'react';

const Breadcrumb = ({ items }) => {
    return (

            <ol className="list-none p-0 inline-flex">
                {items.map((item, index) => (
                    <li key={index} className="flex items-center text-gray-500 hover:text-gray-800">
                        {index > 0 && <span className="mx-2"> / </span>}
                        <a href={item.url} className="">
                            {item.label}
                        </a>
                    </li>
                ))}
            </ol>

    );
};

export default Breadcrumb;
