import React, {useEffect} from "react";
import Navbar from "../components/common/navbar";
import Reach_us from "../components/common/reach_us";
import Footer from "../components/common/footer";
import {Link, useNavigate} from "react-router-dom";
import AOS from "aos";

import main_flag from "../images/products/flags/flag-main.webp";
import main_signage from "../images/products/signage/signage-main.webp";
import main_board from "../images/products/boards/board-main.webp";
const Products = () => {


    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);



    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Add smooth scrolling effect
        });
    };

    return (
        <div className="bg-neutral-950">
            <Navbar/>
            <div className="mx-auto">
                <section>
                    <div
                        className="relative  h-[400px] lg:h-[500px] w-screen overflow-hidden bg-cover bg-no-repeat bg-[url('https://images.unsplash.com/photo-1627384113710-424c9181ebbb?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]">
                        <div
                            className="absolute max-h-[400px] lg:max-h-[600px] inset-0 backdrop-blur-none bg-black/60 flex justify-center items-center">
                            <div className="text-center">
                                <h1 className="text-neutral-200 text-3xl font-black uppercase">
                                    Products
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="container px-1 md:px-12">
                        <div
                            className="block h-full rounded-lg bg-[hsla(0,0%,100%,0.8)] px-4 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,20%,0.3)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] ">
                            <section className="w-full mx-auto py-10 flex flex-col gap-5  dark:text-white">


                                <div className="flex flex-col md:flex-row gap-3 xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto md:justify-center md:items-center mt-4" data-aos="slide-left">
                                    <div className="w-full md:w-[50%] xs:w-full">
                                        <img className="rounded-xl" src={main_flag} alt="billboard image" />

                                    </div>
                                    <div className="w-full md:w-[50%] dark:text-gray-400 md:p-4 xs:p-0 rounded-md" data-aos="slide-up">
                                        <h2 className="text-3xl mb-2 font-extrabold text-gray-900 dark:text-white uppercase">Flags</h2>
                                        <span className={'text-pantone hidden lg:block uppercase font-bold text-sm'}>Introduction</span>
                                        <p className="text-md mt-2 hidden lg:block mb-4 text-gray-300 text-sm">
                                            Product flags are a feature commonly used in e-commerce and retail platforms to highlight specific attributes or characteristics of a product. These flags serve various purposes, such as indicating special offers, highlighting product status (e.g., new arrival, bestseller), or conveying important information (e.g., limited stock, clearance).
                                        </p>

                                        <span className={'text-pantone uppercase font-bold text-sm'}>Flags we Offer:</span>
                                        <p className="text-sm text-gray-300 mt-2">Water Base Flags</p>
                                        <p className="text-sm text-gray-300">Banner Flags</p>
                                        <p className="text-sm text-gray-300">Concrete Base Flags</p>

                                        <div className="justify-end items-end flex">
                                         <button onClick={() => {navigate('/products/flags'); scrollToTop();}} className={'mt-3 text-gray-300 text-md px-8 flex items-center rounded-full justify-center bg-pantone/90 hover:bg-transparent hover:text-pantone font-medium h-[40px] cursor-pointer hover:border border-pantone'}>Check Out Flags</button>
                                        </div>
                                        </div>
                                </div>



                                <div className="flex flex-col md:flex-row gap-3 xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto md:justify-center md:items-center mt-6" data-aos="slide-up">
                                    <div className="w-full md:w-[50%] md:order-2">
                                        <img className="rounded-xl" src={main_signage} alt="billboard image" />
                                    </div>

                                    <div className="w-full md:w-[50%] dark:text-gray-400 md:p-4 xs:p-0 rounded-md md:order-1">
                                        <h2 className="text-3xl mb-2 font-extrabold text-gray-900 dark:text-white uppercase">Signages</h2>
                                        <span className={'text-pantone hidden lg:block uppercase font-bold text-sm'}>Introduction</span>
                                        <p className="text-md mt-2 hidden lg:block mb-4 text-gray-300 text-sm">
                                            Signages are visual communication tools used to convey information, directions, promotions, or warnings in various settings, including retail stores, public spaces, workplaces, and events. They play a crucial role in guiding and informing individuals, enhancing brand visibility, and influencing behavior.
                                        </p>

                                        <span className="text-pantone uppercase font-bold text-sm">Signages we Offer:</span>
                                        <p className="text-sm text-gray-300 mt-2">3D Signages</p>
                                        <p className="text-sm text-gray-300">Door Signages</p>
                                        <p className="text-sm text-gray-300">Floating Signages</p>
                                        <p className="text-sm text-gray-300">Directional Signages</p>

                                        <div className="justify-end items-end flex">
                                            <button onClick={() => {navigate('/products/signages'); scrollToTop();}} className={'mt-3 text-gray-300 text-md px-8 flex items-center rounded-full justify-center bg-pantone/90 hover:bg-transparent hover:text-pantone font-medium h-[40px] cursor-pointer hover:border border-pantone'}>Check Out Signages</button>
                                        </div>
                                        </div>
                                </div>





                                <div className="flex flex-col md:flex-row gap-3 xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto md:justify-center md:items-center mt-4" data-aos="slide-right">
                                    <div className="w-full md:w-[50%] xs:w-full">
                                        <img className="rounded-xl" src={main_board} alt="billboard image" />
                                    </div>
                                    <div className="w-full md:w-[50%] dark:text-gray-400 md:p-4 xs:p-0 rounded-md" data-aos="slide-up">
                                        <h2 className="text-3xl mb-2 font-extrabold text-gray-900 dark:text-white uppercase">Advertising Boards</h2>
                                        <span className={'text-pantone hidden lg:block uppercase font-bold text-sm'}>Introduction</span>
                                        <p className="text-md mt-2 hidden lg:block mb-4 text-gray-300 text-sm">
                                            Product flags are a feature commonly used in e-commerce and retail platforms to highlight specific attributes or characteristics of a product. These flags serve various purposes, such as indicating special offers, highlighting product status (e.g., new arrival, bestseller), or conveying important information (e.g., limited stock, clearance).
                                        </p>

                                        <span className={'text-pantone uppercase font-bold text-sm'}>Advertising Boards we Offer:</span>
                                        <p className="text-sm text-gray-300 mt-2">Hoardings</p>
                                        <p className="text-sm text-gray-300">Static Boards</p>

                                        <div className="justify-end items-end flex">
                                            <button onClick={() => {navigate('/products/advertising-boards'); scrollToTop();}} className={'mt-3 text-gray-300 text-md px-8 flex items-center rounded-full justify-center bg-pantone/90 hover:bg-transparent hover:text-pantone font-medium h-[40px] cursor-pointer hover:border border-pantone'}>Check Out Advertising Boards</button>
                                        </div>
                                    </div>
                                </div>







                            </section>

                        </div>
                    </div>
                </section>
            </div>
            <Reach_us/>
            <Footer/>
        </div>
    )
}

export default Products;