import React, {useEffect, useState} from 'react';

import blogimg1 from '../components/assets/img1.jpg'
import Navbar from "../components/common/navbar";
import Footer from "../components/common/footer";
import AOS from "aos";
import filter_blog from "../components/common/filter_blog";
import blog_posts from "../components/common/blog_featured_posts";
import blog_news from "../components/common/blog_recent_news";




function Blog() {


    const [selectedFilter, setSelectedFilter] = useState('all');

    const handleFilterButtonClick = (filterType) => {
        setSelectedFilter(filterType)
    };



    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);


    return (
        <div className="bg-neutral-950">
            <Navbar/>
            <div className="  mx-auto">
                <section className=" ">
                    <div
                        className="relative h-[400px] overflow-hidden bg-cover bg-no-repeat bg-[url('https://mdbcdn.b-cdn.net/img/new/textures/full/284.jpg')]">
                        <div
                            className="absolute inset-0 backdrop-blur-none bg-black/60 flex justify-center items-center">
                            <div className="text-center">
                                <h1 className="text-neutral-200 text-3xl font-black uppercase">Our Blog</h1>
                            </div>
                        </div>

                    </div>
                    <div className="container px-6 md:px-12 max-w-[1380px]">
                        <div
                            className="font-bold !h-10 resize-y align-top bg-neutral-950/60 px-2 py-2.5 -mt-[90px]  backdrop-blur-[30px] text-neutral-200 flex flex-row md:flex-row ">
                            <div className="">
                                <button
                                    className={`px-2  uppercase text-xs  ${selectedFilter === 'all' ? 'text-pantone' : 'hover:text-pantone focus:pantone focus:underline-offset-8'}`}
                                    onClick={() => handleFilterButtonClick('all')}>
                                    All
                                </button>
                            </div>
                            <div className=" ">
                                <button
                                    className={`px-2 uppercase text-xs  ${selectedFilter === 'retail' ? 'text-pantone' : 'hover:text-pantone focus:pantone focus:underline-offset-8'}`}
                                    onClick={() => handleFilterButtonClick('retail')}>
                                    Retail
                                </button>
                            </div>
                            <div className="">
                                <button
                                    className={`px-2   uppercase text-xs  ${selectedFilter === 'sports' ? 'text-pantone' : 'hover:text-pantone focus:pantone focus:underline-offset-8'}`}
                                    onClick={() => handleFilterButtonClick('sports')}>
                                    Sports & Events
                                </button>
                            </div>
                            <div className="">
                                <button
                                    className={`px-2   uppercase text-xs  ${selectedFilter === 'news' ? 'text-pantone' : 'hover:text-pantone focus:pantone focus:underline-offset-8'}`}
                                    onClick={() => handleFilterButtonClick('news')}>
                                    News
                                </button>
                            </div>
                        </div>
                        <div
                            className="block mt-[-0] bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,20%,0.3)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px]">
                            <div className="px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
                                <div className="mx-auto max-w-screen-sm text-center  mb-8">
                                    <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">We
                                        use
                                        an
                                        agile approach to test assumptions and connect with the needs of
                                        your
                                        audience early and often.</p>
                                </div>
                            </div>
                            <section className="flex flex-col md:flex-row background-color: bg-transparent ">
                                <div className="flex flex-col">
                                    {filter_blog.filterData.map((item, index) => {
                                        if (selectedFilter === '' || item.id === selectedFilter || item.type === selectedFilter) {
                                            return (
                                                <div>
                                                    <div className="max-w-2xl md:mr-10">
                                                        <article
                                                            className="mb-5 lg:mr-20 p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
                                                            data-aos="fade-up">
                                                            <div
                                                                className="flex justify-between items-center mb-5 text-gray-500">
                                              <span
                                                  className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                                                  <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20"
                                                       xmlns="http://www.w3.org/2000/svg"><path
                                                      d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>
                                                  Tutorial
                                              </span>
                                                                <span className="text-sm">14 days ago</span>
                                                            </div>
                                                            <img className="rounded-lg" src={item.img}/>
                                                            <h2 className="mb-2 text-2xl font-bold tracking-tight text-black dark:text-white">
                                                                <a href="#">{item.title}</a></h2>
                                                            <p className="mb-5 font-light text-gray-500 dark:text-gray-400">{item.desc}</p>
                                                            <div className="flex justify-between items-center">
                                                                <div className="flex items-center space-x-4">
                                                                    <img className="w-7 h-7 rounded-full"
                                                                         src={item.img}
                                                                         alt="Jese Leos avatar"/>
                                                                    <span className="font-medium dark:text-white">
                                                                 Jese Leos
                                                            </span>
                                                                </div>
                                                                <a href="news"
                                                                   className="inline-flex items-center font-medium text-yellow-700 dark:text-primary-500 hover:underline">
                                                                    Read more
                                                                    <svg className="ml-2 w-4 h-4" fill="currentColor"
                                                                         viewBox="0 0 20 20"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd"
                                                                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                                              clip-rule="evenodd"></path>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>

                                            )
                                            return null;
                                        }
                                    })}
                                </div>
                                <div className=" md:mt-[-20] md:max-w-md sm:max-w-sm flex-row">
                                    <div>
                                        <h1 className="text-neutral-200 uppercase text-xl font font-bold mb-5"
                                            data-aos="fade-left">Featured
                                            Posts</h1>
                                        {blog_posts.postData.map((postItem, index) => {
                                            return (
                                                <div className="mb-5 flex flex-row" data-aos="fade-left">
                                                    <div>
                                                        <img className="aspect-square rounded-lg mr-5 max-w-48 max-h-32"
                                                             src={postItem.img}/>
                                                    </div>
                                                    <div>
                                                        <h2 className="px-2 md:max-w-md text-neutral-200 uppercase text-xl font font-bold">{postItem.title}</h2>
                                                        <p className="text-gray-500 mt-4 ml-2">{postItem.date}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <h1 className="text-neutral-200 uppercase text-xl font font-bold mb-5 mt-5"
                                        data-aos="fade-left">Recent news</h1>
                                    <div className="mb-5">
                                        {blog_news.newsData.map((newsItem, index) => {
                                            return (
                                                <div class="root" className="mb-5 relative" data-aos="fade-left">
                                                    <div className="mb-5">
                                                        <img alt="news" className=" rounded-lg  brightness-50"
                                                             src={newsItem.img}/>
                                                        <div className="absolute bottom-0 px-4 py-3">
                                                            <h1 className="text-neutral-200 font-bold uppercase ">{newsItem.title}</h1>
                                                            <p className="text-gray-400">{newsItem.desc}</p>
                                                        </div>
                                                    </div>

                                                </div>

                                            )
                                        })}
                                    </div>

                                </div>


                            </section>


                        </div>

                    </div>
                </section>


            </div>


            <Footer/>

        </div>
    );
}

export default Blog;