import React, {useEffect, useRef, useState} from 'react';
import AOS from "aos";
const HomeFaq = () => {
    const FaqsCard = ({ faqsList, idx }) => {
        const answerElRef = useRef();
        const [state, setState] = useState(false);
        const [answerH, setAnswerH] = useState('0px');

        const handleOpenAnswer = () => {
            const answerElH = answerElRef.current.childNodes[0].offsetHeight;
            setState(!state);
            setAnswerH(`${answerElH + 20}px`);
        };

        useEffect(() => {
            AOS.init({
                duration: 1000, // Adjust the duration of the animation
                once: true, // Set true if you want the animation to occur only once
            });
        }, []);

        return (
            <div
                className="space-y-3 mt-5 overflow-hidden border-b"
                key={idx}
                onClick={handleOpenAnswer}
            >
                <h4 className="cursor-pointer pb-0.5 flex items-center justify-between text-lg text-white font-medium"  data-aos="fade-up">
                    {faqsList.q}
                    {state ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-red-600 ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-pantone ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                    )}
                </h4>
                <div
                    ref={answerElRef}
                    className="duration-300"
                    style={state ? { height: answerH } : { height: '0px' }}
                >
                    <div>
                        <p className="text-pantone"  >{faqsList.a}</p>
                    </div>
                </div>
            </div>
        );
    };

    const faqsList = [
        {
            q: "What are some random questions to ask?",
            a: "That's exactly the reason we created this random question generator. There are hundreds of random questions to choose from so you're able to find the perfect random question."
        },
        {
            q: "Do you include common questions?",
            a: "This generator doesn't include most common questions. The thought is that you can come up with common questions on your own so most of the questions in this generator."
        },
        {
            q: "Can I use this for 21 questions?",
            a: "Yes! there are two ways that you can use this question generator depending on what you're after. You can indicate that you want 21 questions generated."
        },

        // ... (other FAQ items)
    ];

    return (
        <div>


            <div class=" mt-32   max-w-7xl mx-auto bg-neutral-950" >
                <section class=" pb-10">
                    {/*<div class="relative h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat bg-[url('https://mdbcdn.b-cdn.net/img/new/textures/full/284.jpg')]"></div>*/}
                    <div class=" px-6 md:px-12">
                        <div class="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,20%,0.3)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] ">

                            <section className="leading-relaxed max-w-screen-xl  mx-auto px-4 md:px-8 ">
                                <div className="space-y-3 text-center">
                                    <h1 className="text-3xl text-white font-semibold" data-aos="zoom-in">FAQ</h1>
                                    <p className="text-white max-w-lg mx-auto text-lg" data-aos="zoom-in">
                                        Answered all frequently asked questions, Still confused? feel free to contact us.
                                    </p>
                                </div>
                                <div className=" max-w-6xl mx-auto">
                                    {faqsList.map((item, idx) => (
                                        <FaqsCard key={idx} faqsList={item} idx={idx} />
                                    ))}
                                </div>
                            </section>
                        </div>

                    </div>
                </section>
            </div>



        </div>
    );
};

export default HomeFaq;
