import React, { useEffect, useState } from "react";
import AOS from "aos";
import Navbar from "../../components/common/navbar";
import { Link } from "react-router-dom";
import Reach_us from "../../components/common/reach_us";
import Footer from "../../components/common/footer";
import axios from "axios";
import MySQLUrl from "../../backendURL";

const Signages = () => {
    const [mainData, setMainData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [currentSubImages, setCurrentSubImages] = useState([]);
    const [visibleProjects, setVisibleProjects] = useState([]);

    useEffect(() => {
        axios
            .get(`${MySQLUrl}/products/`)
            .then((res) => {
                setMainData(res.data);
                // Filter data with category_id as 1
                const filteredData = res.data.filter((item) => item.category_id === 2);
                setCurrentData(filteredData);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        const requests = currentData.map((data) => {
            return axios
                .get(`${MySQLUrl}/products/images/byid/${data.product_id}`)
                .then((imageRes) => {
                    return imageRes.data;
                })
                .catch((err) => {
                    console.log(err);
                    return null;
                });
        });

        Promise.all(requests)
            .then((results) => {
                const filteredResults = results.filter((result) => result !== null);
                setCurrentSubImages(filteredResults);
                // Initialize visibility state for each project
                setVisibleProjects(Array(filteredResults.length).fill(false));
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentData]);

    const toggleVisibility = (index) => {
        setVisibleProjects((prevVisibility) => {
            const updatedVisibility = [...prevVisibility];
            updatedVisibility[index] = !updatedVisibility[index];
            return updatedVisibility;
        });
    };

    useEffect(() => {
        AOS.init({
            duration: 1500,
            once: true,
        });
    }, []);

    return (
        <div className="bg-neutral-950">
            <Navbar />
            <div className="mx-auto">
                <section>
                    <div className="relative h-[400px] overflow-hidden bg-cover bg-no-repeat bg-[url('https://b-expression.com/wp-content/uploads/2019/12/qatar.jpg')]">
                        <div className="absolute max-h-[400px] inset-0 backdrop-blur-none bg-black/60 flex justify-center items-center">
                            <div className="text-center">
                                <h1 className="text-neutral-200 text-3xl font-black uppercase">Signages</h1>
                            </div>
                        </div>
                    </div>
                    <div className="container px-1 md:px-12">
                        <div className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-4 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,20%,0.3)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] ">
                            {currentData.map((currentItem, index) => (
                                <div key={index} className={"mt-12 "} data-aos={"fade-right"}>
                                    {/* Image */}
                                    <div className={"flex flex-col md:flex-row"}>
                                        <div className={`w-full md:w-6/12 flex justify-center items-center ${index % 2 === 0 ? 'order-1 md:order-2' : 'order-2 md:order-1'}`}>
                                            <img
                                                className={"w-full  h-[400px] md:w-4/5 object-cover rounded-lg"}
                                                src={`${MySQLUrl}/products/image${currentItem.image}`}
                                                alt={"img"}
                                            />
                                        </div>

                                        {/* Content */}
                                        <div className={`w-full md:w-6/12 flex flex-col gap-3 md:ml-6 ${index % 2 === 0 ? 'order-2 md:order-1 justify-start items-start' : 'pl-12 order-1 md:order-2 items-start text-start'}`}>
                                            <div className={`flex ${index % 2 === 0 ? 'justify-start items-start':'justify-start items-start'}`}>
                                                <h1 className={"text-2xl mb-1 font-extrabold text-gray-900 dark:text-white uppercase"}>{currentItem.title}</h1>
                                            </div>
                                            {currentItem.description ? (
                                                <div className={"w-full md:w-9/12"}> {/* Adjust width for mobile */}
                                                    <span className={'text-pantone hidden lg:block uppercase font-bold text-sm'}>SIGNAGES</span>
                                                    <p className={"text-md mt-2 hidden lg:block mb-4 text-gray-300 text-sm text-left"}>{currentItem.description}</p>
                                                </div>
                                            ) : null}
                                            <div>
                                                <button
                                                    onClick={() => toggleVisibility(index)}
                                                    className={"px-3 py-1 bg-pantone text-white font-extralight rounded-lg"}
                                                >
                                                    Check Out More
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Gallery */}
                                    {visibleProjects[index] && (
                                        <div className={"w-full flex flex-col justify-center items-center mt-2"}>
                                            <h1 className={"text-2xl text-center font-extrabold uppercase text-white mt-4 mb-4"}>
                                                Project <span className={"text-pantone"}>Gallery</span>
                                            </h1>
                                            <div className={"grid grid-cols-3 gap-5"}>
                                                {currentSubImages[index]?.map((image, i) => (
                                                    <div className={"mb-4"} key={i} data-aos={"fade-up"}>
                                                        <img
                                                            className="h-[350px] md:h-[200px] w-[500px] object-cover rounded-lg transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                                            src={`${MySQLUrl}/products/image${image.imagePath}`}
                                                            alt={`Image`}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}

                        </div>
                    </div>
                </section>
            </div>
            <Reach_us />
            <Footer />
        </div>
    );
};

export default Signages;
