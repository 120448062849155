const blog_posts = {
    postData:[
        {
            type:'post',
            title:'The Brand Expression company becomes a FIFA world Cup Sponsor',
            date:'March 14 2024',
            img:'https://logowik.com/content/uploads/images/fifa-world-cup-qatar-20229564.jpg'
        },

        {
            type:'post',
            title:'The Brand Expression company becomes a FIFA world Cup Sponsor',
            date:'March 14 2024',
            img:'https://logowik.com/content/uploads/images/fifa-world-cup-qatar-20229564.jpg'
        },

        {
            type:'post',
            title:'The Brand Expression company becomes a FIFA world Cup Sponsor',
            date:'March 14 2024',
            img:'https://logowik.com/content/uploads/images/fifa-world-cup-qatar-20229564.jpg'
        },

    ]
}

export default blog_posts;