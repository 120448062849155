const blog_news = {
    newsData:[
        {
            type:'news',
            title:'The Brand Expression company becomes a FIFA world Cup Sponsor',
            desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
            img:'https://www.isspf.com/wp-content/uploads/2022/09/shutterstock_2190840355-scaled.jpg'
        },

        {
            type:'news',
            title:'The Brand Expression company becomes a FIFA world Cup Sponsor',
            desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
            img:'https://www.isspf.com/wp-content/uploads/2022/09/shutterstock_2190840355-scaled.jpg'
        },

    ]
}

export default blog_news;