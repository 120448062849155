const filter = {
  filterData:[
      {
          id:1,
          title:'Our Company',
          desc:'At The Brand Expression, we are your premier destination for all your printing and installation\n' +
              'needs. With a staggering 9000 sqm printing capacity and a diverse range of capabilities, we bring\n' +
              'your visions to life with unparalleled precision and quality.\n' +
              'Our state-of-the-art facility boasts cutting-edge technology, enabling us to produce a wide array\n' +
              'of materials including polywoven fabric, vinyl stickers, banner ex, and high-quality UV roll to roll\n' +
              'prints. Whether you need vibrant banners, eye-catching stickers, or intricate signage, we have the\n' +
              'expertise and capacity to deliver beyond expectations.\n' +
              'Not stopping there, our commitment to excellence extends beyond the printing press. We take\n' +
              'pride in our highly talented project management team, ensuring seamless coordination and\n' +
              'execution of every project. Backed by years of experience and a keen eye for detail, our team\n' +
              'ensures your project is delivered on time and within budget.\n' +
              'Furthermore, our skilled and experienced installation team stands ready to bring your designs to\n' +
              'life in the physical world. With an in-house installation capacity of around 50 professionals,\n' +
              'backed by an extendable network of 250 installers, we handle projects of any scale with utmost\n' +
              'eciency and expertise.\n' +
              'At The Brand Expression, customer satisfaction is our top priority. Whether you need a quote,\n' +
              'have a question, or seek advice on a project, our dedicated team is here to assist you every step\n' +
              'of the way. Give us a call today and let us turn your ideas into reality!',
          img:'https://images.unsplash.com/photo-1492684223066-81342ee5ff30?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
      {
          id:2,
          title:'Our Team',
          desc:'The hypothetical team at our company is a dynamic ensemble of skilled professionals dedicated to delivering excellence in product and service offerings. Comprising experts from diverse fields such as creative design, digital printing, and technical solutions, this team operates in synergy to cater to the individual needs of our clients. The creative team, composed of visionary designers and content creators, brings artistic flair and brand storytelling expertise to every project. Working seamlessly with our experienced technicians, who possess a deep understanding of cutting-edge technologies, the team ensures the flawless execution of digital printing and signage solutions.\n' +
              '\n' +
              'Each team member is committed to upholding the highest standards of quality and innovation, striving to exceed client expectations in every project undertaken. Their collaborative approach fosters an environment where creativity meets technical precision, resulting in bespoke solutions that resonate with our clients\' unique business identities. The team\'s unwavering dedication, combined with their ability to adapt to emerging trends and technologies, positions them as invaluable contributors to the company\'s mission of delivering top-tier products and services to businesses. Their collective expertise, enthusiasm, and commitment to client success define this hypothetical team, making it an integral part of our company\'s commitment to excellence.',
          img:'https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?q=80&w=2047&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
      {
          id:3,
          title:'Our Facilities',
          desc:'Welcome to our state-of-the-art facilities designed to elevate your business experience! Our cutting-edge infrastructure is meticulously crafted to meet the highest industry standards, ensuring optimal efficiency and functionality. From advanced digital printing studios equipped with the latest technology to modern workspaces that foster creativity and collaboration, our facilities are tailored to provide a seamless and enriching environment.\n' +
              '\n' +
              'Our production facilities boast the latest in digital printing technology, allowing us to deliver high-quality prints with precision and speed. Meanwhile, our workspaces are designed to inspire innovation, featuring collaborative areas for brainstorming sessions and individual workstations equipped with the tools needed for optimal productivity.',
          img:'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      }
  ]
}

export default filter;