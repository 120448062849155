import logo from '../assets/footer-logo.webp';

import ISO1 from '../../images/iso/ISO-1.png';
import ISO2 from '../../images/iso/ISO-2.png';
import ISO3 from '../../images/iso/ISO-3.png';


import {FaEnvelope, FaPhone, FaFax, FaMapMarkerAlt} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';



function Footer() {

    const navigate = useNavigate(); // Create a navigate function for navigation

    // Function to handle click event and scroll to top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Add smooth scrolling effect
        });
    };


    return (


        <footer className="bg-neutral-950 text-white shadow-lg rounded-t-lg mt-24">
            <div className="container mx-auto py-2 px-4">

                <div className="flex flex-col md:flex-row md:items-center justify-center md:justify-between border-t">

                    <div className="w-full md:w-2/6 mb-4 md:mb-0 md:ml-4 flex justify-center md:justify-start">
                        <div className="block md:flex md:flex-col md:items-center">
                            <div className="flex flex-wrap justify-center md:justify-start">
                                <img src={logo} className="w-[250px]  md:w-[260px] md:h-auto" alt="Logo" />
                            </div>
                            <div className="flex mt-5 md:mt-0 md:flex md:flex-wrap md:justify-center">
                                <img src={ISO1} alt="Button 1" className="w-20 h-20 mr-2 mb-2 cursor-pointer" />
                                <img src={ISO2} alt="Button 1" className="w-20 h-20 mr-2 mb-2 cursor-pointer" />
                                <img src={ISO3} alt="Button 1" className="w-20 h-20 mr-2 mb-2 cursor-pointer" />
                            </div>
                        </div>
                    </div>






                    <div className="w-full md:w-4/6 mt-5">

                        <div className="flex flex-wrap">
                            <div className="w-full md:w-1/3 text-center md:text-left mb-8 md:mb-0 items-center justify-center">

                                <div className="block justify-start text-left">
                                     <h2 className="text-md font-bold mb-3">PRODUCTS</h2>
                                </div>
                                <div className="block justify-start text-left">
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/products/advertising-boards'); scrollToTop(); }}>Advertising Boards</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/products/signages'); scrollToTop(); }}>Flags</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/products/flags'); scrollToTop(); }}>Signages</p>
                                </div>
                            </div>

                            <div className="w-full md:w-1/3 text-center md:text-left mb-8 md:mb-0 items-center justify-center">

                                <div className="block justify-start text-left">
                                    <h2 className="text-md font-bold mb-3 uppercase">What We Offer</h2>
                                </div>
                                <div className="block justify-start text-left">
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/branding-services'); scrollToTop(); }}>Branding Services</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/corporate-gifts'); scrollToTop(); }}>Corporate Gifts</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/design-services'); scrollToTop(); }}>Design Services</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/digital-printing'); scrollToTop(); }}>Digital Printing</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/event-management'); scrollToTop(); }}>Event Management</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/showroom-display'); scrollToTop(); }}>Showroom Display</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/signages'); scrollToTop(); }}>Signages</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/trade-show-display'); scrollToTop(); }}>Trade Show Display</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/vehicle-wraps'); scrollToTop(); }}>Vehicle Wraps</p>
                                    <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/services/wall-and-window-graphics'); scrollToTop(); }}>Wall & Window Graphics</p>


                                </div>
                            </div>


                            <div className="w-full md:w-1/3 text-center md:text-left mb-8 md:mb-0 items-center justify-center">

                                <div className="block justify-start text-left">
                                    <h2 className="text-md font-bold mb-3 uppercase">Reach Us</h2>
                                </div>
                                <div className="block justify-start text-left">
                                    <div className="flex items-center md:flex-row md:items-center mb-2">
                                        <FaEnvelope className="mr-2 h-4 w-4" />
                                        <p className="hover:text-pantone hover:cursor-pointer font-thin text-sm">info@b-expression.com</p>
                                    </div>

                                    <div className="flex items-center md:flex-row md:items-center mb-2">
                                        <FaPhone class="mr-2 h-4 w-4" />
                                        <p className="hover:text-pantone hover:cursor-pointer font-thin text-sm">+(974) 44 44 1278</p>
                                    </div>

                                    <div className="flex items-center md:flex-row md:items-center mb-2">
                                        <FaFax class="mr-2 h-4 w-4" />
                                        <p className="hover:text-pantone hover:cursor-pointer font-thin text-sm">+(974) 44 44 1279</p>
                                    </div>

                                    <div className="flex items-center md:flex-row md:items-center mb-2">
                                        <FaMapMarkerAlt class="mr-2 h-4 w-4" />
                                        <p className="hover:text-pantone hover:cursor-pointer font-thin text-sm">PO Box: 14453, <br/>Gate 76, Street 36,<br/> Industrial Area,<br/> Doha, State of Qatar</p>
                                    </div>
                                </div>
                            </div>

                        </div>



                        <div className="w-full md:w-1/3 text-center md:text-left mb-8 md:mb-0 items-center justify-center">

                            <div className="block justify-start text-left">
                                <h2 className="text-md font-bold mb-3 uppercase">our work</h2>
                            </div>
                            <div className="block justify-start text-left">
                                <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/our-work/ceremonies-and-sports'); scrollToTop(); }}>Ceremonies & Sports</p>
                                <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/our-work/cultural'); scrollToTop(); }}>Cultural</p>
                                <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/our-work/education'); scrollToTop(); }}>Education</p>
                                <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/our-work/entertainment'); scrollToTop(); }}>Entertainment</p>
                                <p className="hover:text-pantone hover:cursor-pointer font-thin mb-1 text-sm" onClick={() => { navigate('/our-work/technology'); scrollToTop(); }}>Technology</p>
                            </div>
                        </div>


                    </div>

                </div>


                <div className="mt-14 border-t border-gray-100 pt-2 flex flex-col items-center md:flex-row md:justify-between pb-0 mb-0 pr-8">
                    <div className="block sm:items-center sm:justify-between w-full md:w-auto mb-4 md:mb-0 text-center sm:text-left">
                        <ul className="flex flex-wrap gap-4 text-xs mb-2 justify-center sm:justify-start">
                            <li>
                                <a href="#" className="text-gray-500 transition hover:opacity-75">Terms & Conditions</a>
                            </li>
                            <li>
                                <a href="#" className="text-gray-500 transition hover:opacity-75">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="#" className="text-gray-500 transition hover:opacity-75">Cookies</a>
                            </li>
                        </ul>
                        <p className="text-xs text-gray-500 sm:mt-0">&copy; 2024. Brand Expression. All rights reserved.</p>
                    </div>

                    <div className="flex justify-center items-center">
                        <p className="text-xs text-gray-500 sm:mt-0 mr-3">Powered By:</p>
                        <img src={"https://alomar-marine.com/Images/logo-u.webp"} className="w-[115px]" alt="TechZap Logo"/>
                    </div>
                </div>

            </div>
        </footer>



    );
}

export default Footer;
