import React, { useEffect, useState } from 'react';

const Sample = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentPosition = window.scrollY;
            setScrollPosition(currentPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getImageStyle = () => {
        // Move the image 50px to the right when scrolling down
        const moveRight = scrollPosition > 0 ? 50 : 0;

        return {
            transform: `translateX(${moveRight}px)`,
            transition: 'transform 0.3s ease-in-out' // Add transition for smooth animation
        };
    };

    return (
        <div style={{ height: '2000px', position: 'relative' }}>
            <div
                style={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: 'blue',
                    position: 'fixed',
                    top: '50px',
                    left: '50px',
                    ...getImageStyle() // Apply dynamic styles based on scroll position
                }}
            ></div>
        </div>
    );
};

export default Sample;
