import React, { useState, useEffect } from 'react';

import image1 from '../../images/homepage-carousel/1.jpg';
import image2 from '../../images/homepage-carousel/2.jpg';
import image3 from '../../images/homepage-carousel/3.jpg';
import image4 from '../../images/homepage-carousel/4.jpg';
import '../../css/TypeAnimation.css';
import {TypeAnimation} from "react-type-animation";

import '../../css/button.css';
import {Link} from "react-router-dom";

import scrollBtn from '../assets/buttons/scroll.png';

const HomeCarousel = ({ homeAboutRef }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [image2, image3, image4]; // Add more images as needed

    const scrollToHomeAbout = () => {
        if (homeAboutRef.current) {
            homeAboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4500); // Change image every 3 seconds (3000 milliseconds)

        return () => clearInterval(interval);
    }, );



    return (
        <div className="relative bg-current text-gray-800 h-[100vh] sm:[80vh] max-w-full mx-auto inset-0 flex justify-center items-center">
            <div className="absolute inset-0" style={{ backgroundImage: `url(${images[currentIndex]})`, backgroundSize: 'cover', backgroundPosition: 'center center', transition: 'background-image 1s ease-in-out' }}>
                <div className="absolute inset-0 backdrop-blur-none bg-black/60 ">
                    <div className="h-[90vh] flex items-center ">
                        <div className="block mx-auto mt-24 max-w-7xl ">
                            <div className=" mx-3 text-neutral-400 text-3xl font-black custom-type-animation">
                                <TypeAnimation
                                    sequence={[
                                        'Branding Services',
                                        2000,
                                        'Corporate Events',
                                        2000,
                                        'Sports Events',
                                        2000,
                                        'Exhibitions',
                                        2000,
                                        'Multimedia Services',
                                        2000,
                                        'Public Events',
                                        2000,
                                    ]}
                                    speed={20}
                                    style={{ fontSize: '2em' }}
                                    repeat={Infinity}
                                    cursor={false}
                                />
                            </div>
                            <div className="mt-3 mx-3 lg:w-1/2">
                                <h5 className="text-gray-300 text-md md:text-lg leading-normal">
                                    Brand Expression is one of the leading independent, creatively driven full service marketing
                                    communications agency & brand production company born & groomed in Doha - State of Qatar.
                                </h5>
                            </div>
                            <div className="mt-4 w-5/12 flex flex-end justify-end">

                                <button className="text-gray-300 text-lg flex items-center rounded-full justify-center bg-pantone/70 hover:bg-transparent hover:text-pantone font-bold h-[40px] w-[150px] cursor-pointer hover:border border-pantone">
                                    {/* Text */}
                                    Contact Now
                                </button>


                            </div>
                        </div>
                    </div>

                    <div className="h-[10vh] flex items-center justify-center animate-bounce">
                        <div>
                            <Link to="#" onClick={scrollToHomeAbout}>
                                <img src={scrollBtn} className="h-[40px]" alt="Scroll button"/>
                            </Link>
                        </div>
                    </div>



                </div>
            </div>
        </div>

    );
};

export default HomeCarousel;