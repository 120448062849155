import React, {useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import AOS from "aos";
import '../../css/button.css';




const Gallery = () => {

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Add smooth scrolling effect
        });
    };

    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);

    return (

        <div className="max-w-7xl  mx-auto bg-neutral-950 mb-5 mt-24">

            <div className=" sm:mx-auto sm:text-center" data-aos="slide-up">

                <h3 className="uppercase p-5 text-neutral-300 font-bold" style={{
                    fontSize: '32px',
                }}>
                    <span className={'text-pantone hidden lg:block uppercase font-bold text-sm'}>Who We Are</span>
                    OUR SERVICES
                </h3>

            </div>

            {/*<div className="grid grid-cols-2 md:grid-cols-5 gap-2">*/}

            {/*    <div className="grid gap-2">*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/IAAF_1.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>ONE</p>*/}

            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}

            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/AG_1.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>FIVE</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/QV_EMIRCUP_20017_0008.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>NINE</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*    </div>*/}

            {/*    <div className="grid gap-3">*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group" >*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/AYA_1990.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>TWO</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group " >*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/AYA_2028.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>SIX</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/QP_1.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>TEN</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*    <div className="grid gap-2">*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group" >*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/QP_4.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>THREE</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/AK_1.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>SEVEN</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/SR_1.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>ELEVEN</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*    <div className="grid gap-2">*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/UAH_1.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>FOUR</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden" data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/SWD_1.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>EIGHT</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group" >*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/2018-MOTC-Business-Awards-05.jpeg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>TWELVE</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*    <div className="grid gap-2">*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/UAH_1.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>FOUR</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden" data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group">*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/SWD_1.jpg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>EIGHT</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*        <div onClick={() => navigate("aboutUs" className="relative block overflow-hidden"  data-aos="fade-up">*/}
            {/*            <div className="relative overflow-hidden group" >*/}
            {/*                <img className="h-auto max-w-full rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500" src="https://b-expression.com/wp-content/uploads/2022/05/2018-MOTC-Business-Awards-05.jpeg" alt=""/>*/}
            {/*                <div className="absolute inset-0 flex items-center justify-center">*/}
            {/*                    <p className="text-amber-600 text-center font-bold text-3xl"  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>TWELVE</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={'flex flex-col md:flex-row gap-3 px-4'}>
                <div>
                    <div onClick={() => {navigate('/services/branding-services'); scrollToTop();}} className="relative block overflow-hidden" data-aos="fade-up">
                        <div className="relative overflow-hidden group">
                            <img
                                className="h-[360px] md:h-[365px] w-[1600px] object-cover rounded-lg transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                src="https://b-expression.com/wp-content/uploads/2022/05/IAAF_1.jpg" alt=""/>
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                <p className="text-center font-bold text-xl uppercase relative z-10"
                                   style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                    Branding Services
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'flex flex-col gap-3'}>
                    <div className={'flex flex-row gap-3'}>
                        <div onClick={() => {navigate('/services/corporate-gifts'); scrollToTop();}} className="relative block overflow-hidden" data-aos="fade-up">
                            <div className="relative overflow-hidden group">
                                <img
                                    className="h-[120px] md:h-[170px] w-[800px] object-cover rounded-lg transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                    src="https://b-expression.com/wp-content/uploads/2022/05/AG_1.jpg" alt=""/>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                    <p className="text-center font-bold text-xl uppercase relative z-10"
                                       style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                        Corporate Gifts
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div onClick={() => {navigate('/services/design-services'); scrollToTop();}} className="relative block overflow-hidden" data-aos="fade-up">
                            <div className="relative overflow-hidden group">
                                <img
                                    className="h-[120px] md:h-[170px] w-[800px] object-cover rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                    src="https://b-expression.com/wp-content/uploads/2022/05/QP_4.jpg" alt=""/>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                    <p className="text-center font-bold text-xl uppercase relative z-10"
                                       style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                        Design Services
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div onClick={() => {navigate('/services/signages'); scrollToTop();}} className="relative block overflow-hidden" data-aos="fade-up">
                            <div className="relative overflow-hidden group">
                                <img
                                    className="h-[120px] md:h-[170px] w-[800px] object-cover rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                    src="https://b-expression.com/wp-content/uploads/2022/05/UAH_1.jpg" alt=""/>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                    <p className="text-center font-bold text-xl uppercase relative z-10"
                                       style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                        Signages
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'flex flex-row gap-3'}>
                        <div onClick={() =>{ navigate('/services/digital-printing'); scrollToTop();}} className="relative block overflow-hidden" data-aos="fade-up">
                            <div className="relative overflow-hidden group">
                                <img
                                    className="h-[130px] md:h-[180px] w-[960px] object-cover rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                    src="https://b-expression.com/wp-content/uploads/2022/05/AG_1.jpg" alt=""/>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                    <p className="text-center font-bold text-xl uppercase relative z-10"
                                       style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                        Digital Printing
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div onClick={() => {navigate('/services/showroom-display'); scrollToTop();}} className="relative block overflow-hidden" data-aos="fade-up">
                            <div className="relative overflow-hidden group ">
                                <img
                                    className="h-[130px] md:h-[180px] w-[450px] rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                    src="https://b-expression.com/wp-content/uploads/2022/05/AYA_2028.jpg" alt=""/>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                    <p className="text-center font-bold text-xl uppercase relative z-10"
                                       style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                        Showroom Display
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <div className={'flex flex-col md:flex-row px-4 gap-3'}>
                <div className={'flex flex-col'}>
                    <div className={'flex flex-row  gap-3 mt-3'}>
                        <div onClick={() => {navigate('/services/wall-and-window-graphics'); scrollToTop();}} className="relative block overflow-hidden h-[170px] w-[175px]"
                              data-aos="fade-up">
                            <div className="relative overflow-hidden h-[170px] w-[175px] md:w-[175px] group">
                                <img
                                    className="h-[170px] w-[175px] md:w-[175px] object-cover rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                    src="https://b-expression.com/wp-content/uploads/2022/05/AK_1.jpg" alt=""/>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                    <p className="text-center font-bold text-xl uppercase relative z-10"
                                       style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                        Wall & Window Graphics
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div onClick={() => {navigate('/services/vehicle-wraps'); scrollToTop();}} className="relative block overflow-hidden h-[170px] w-[175px]"
                              data-aos="fade-up">
                            <div className="relative overflow-hidden h-[170px] w-[175px] md:w-[175px] group">
                                <img
                                    className="h-[170px] w-[175px] object-cover rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                    src="https://b-expression.com/wp-content/uploads/2022/05/SWD_1.jpg" alt=""/>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                    <p className="text-center font-bold text-xl uppercase relative z-10"
                                       style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                        Vehicle Wraps
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={' mt-3'}>
                        <div onClick={() => {navigate('/services/trade-show-display'); scrollToTop();}} className="relative block overflow-hidden h-[170px] w-[365px]"
                              data-aos="fade-up">
                            <div className="relative overflow-hidden group h-[170px] w-[365px]">
                                <img
                                    className="h-[170px] w-[365px] object-cover rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                    src="https://b-expression.com/wp-content/uploads/2022/05/QV_EMIRCUP_20017_0008.jpg"
                                    alt=""/>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                    <p className="text-center font-bold text-xl uppercase relative z-10"
                                       style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                        Trade Show Display
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'mt-3'}>
                    <div className="relative block overflow-hidden" data-aos="fade-up" onClick={() => {navigate('/services/event-management'); scrollToTop();}}>
                        <div className="relative overflow-hidden group">
                            <img
                                className="h-[320px] md:h-[360px] w-[860px] object-cover rounded-lg  transform scale-100 group-hover:scale-110 transition-transform duration-500"
                                src="https://b-expression.com/wp-content/uploads/2022/05/QP_1.jpg" alt=""/>
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay Layer */}
                                <p className="text-center font-bold text-xl uppercase relative z-10"
                                   style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', color: '#e17426' }}>
                                    Event Management
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div
                className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:grid-rows-auto md:gap-x-4 md:gap-y-8 px-4 lg:px-8 py-8">


            </div>


        </div>
    );
};

export default Gallery;