const filter_blog = {
    filterData:[
        {
            id:1,
            path:'/services/branding-services',
            type:'Branding-Services',
            title: 'Branding services shape how a company or product is perceived through strategic design and communication',
            img:'https://images.unsplash.com/photo-1416339134316-0e91dc9ded92?q=80&w=1074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },

        {
            id:2,
            path:'/services/corporate-gifts',
            type:'Corporate Gifts',
            title:'\n' +
                'Corporate gifts are tokens given by businesses to clients, employees, or partners.',
            img:'https://images.unsplash.com/photo-1608755728617-aefab37d2edd?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },

        {
            id:3,
            path:'/services/design-services',
            type:'Design Services',
            title:'Design services offer creative solutions for visual and functional needs.',
            img:'https://images.unsplash.com/photo-1512314889357-e157c22f938d?q=80&w=1171&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },

        {
            id:4,
            path:'/services/digital-printing',
            type:'Digital Printing',
            title:'\n' +
                'Digital printing transfers digital files onto substrates efficiently.',
            img:'https://images.unsplash.com/photo-1503694978374-8a2fa686963a?q=80&w=1169&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },

        {
            id:5,
            path:'/services/event-management',
            type:'Event Management',
            title:'Event management coordinates and executes events efficiently.',
            img:'https://images.unsplash.com/photo-1468359601543-843bfaef291a?q=80&w=1174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },

        {
            id:6,
            path:'/services/showroom-display',
            type:'Show Room Display',
            title:'Showroom displays showcase products effectively.',
            img:'https://plus.unsplash.com/premium_photo-1664299504437-5aee21789782?q=80&w=1074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },

        {
            id:7,
            path:'/services/signages',
            type:'Signages',
            title:'Signages communicate messages effectively.',
            img:'https://images.unsplash.com/photo-1564972673005-4c8163da023f?q=80&w=992&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },

        {
            id:8,
            path:'/services/trade-show-display',
            type:'Trade Show Display',
            title:'Blog Post 3',
            img:'https://images.unsplash.com/photo-1572299250382-3f08d3645b2b?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },

        {
            id:9,
            path:'/services/vehicle-wraps',
            type:'Vehicle Wraps',
            title:'Trade show displays showcase products attractively.',
            img:'https://images.unsplash.com/photo-1441148345475-03a2e82f9719?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },

        {
            id:10,
            path:'/services/wall-and-window-graphics',
            type:'Wall & Window Graphics',
            title:'Wall and window graphics enhance spaces creatively.',
            img:'https://images.unsplash.com/photo-1651813338290-2f869def49b0?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },





    ]
}

export default filter_blog;