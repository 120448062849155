import {Fragment, useEffect, useState} from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { useMediaQuery } from 'react-responsive';
import {
    ArrowPathIcon,
    Bars3Icon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
    XMarkIcon,
    UserGroupIcon,
    DocumentIcon,
    GlobeAltIcon,
    BuildingLibraryIcon,
    MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

import image1 from '../../images/homepage-carousel/1.jpg';
import image2 from '../../images/homepage-carousel/2.jpg';
import image3 from '../../images/homepage-carousel/3.jpg';
import image4 from '../../images/homepage-carousel/4.jpg';

import logo from '../../images/navbar/nav-logo.webp';
import closeBtn from '../assets/buttons/close-button.png';
import {Link} from "react-router-dom";
import {Facebook, Instagram, Linkedin, Whatsapp} from "react-bootstrap-icons";

const Project = "project";

const companies = [
    { name: 'Partners', description: 'We guarantee a risk free hiring strategy', href: '/partners', icon: UserGroupIcon },
    { name: 'Projects', description: 'We can streamline the procedure and services', href: '/projects', icon: DocumentIcon },
]

const products = [
    { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
    { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
    { name: 'Security', description: 'Your customers’ filter will be safe and secure', href: '#', icon: FingerPrintIcon },
    { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
    { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
]

const callsToAction = [
    { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
    { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

const servicesToAction = [
    { name: 'Watch Services', href: '#', icon: PlayCircleIcon },
    { name: 'Contact Services', href: '#', icon: PhoneIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setScrolled(isScrolled);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    // Page links
    const AboutPage = "/about-us";
    const OurServices = "/our-services";
    const Digital_print = "/Digital_print";
    const Digital_display = "/digital_display";
    const Commercial_interior = "/commercial_interior"
    const Events_exhibitions = "events_exhibitions";
    const Blog = "/blog";
    const Contactus = "/contact-us";
    const Services = "/services";
    const FaQ = "/FaQ";
    const applicants = "/applicants";
    const Case_studies = "/case_studies";


    return (
        <header className={`fixed-top ${scrolled ? 'bg-black/60' : ''} `}>
            <nav className="mx-auto flex max-w-7xl items-center justify-between " aria-label="Global">
                {/* Render the logo based on screen size and mobileMenuOpen */}
                {(!isMobile || (isMobile && !mobileMenuOpen)) && (
                    <div className="">
                        <Link to="/" className={`${scrolled ? 'm-0.5 p-0.5' : 'm-1.5 p-0.5'}`}>
                            <span className="sr-only">Tee Gee Agency (Pvt) Ltd</span>
                            <img className={`${scrolled ? 'h-[45px]' : 'sm:h-[80px] h-[60px]'} w-auto`} src={logo} alt="Tee Gee Logo" />
                        </Link>
                    </div>
                )}
                {/* Rest of the code remains unchanged */}

                {/*<div className="">*/}
                {/*    <Link to="/" className={`${scrolled ? 'm-0.5 p-0.5' : 'm-1.5 p-0.5'}`}>*/}
                {/*        <span className="sr-only">Tee Gee Agency (Pvt) Ltd</span>*/}
                {/*        <img className={`${scrolled ? 'h-[45px]' : 'h-[80px]'} w-auto`} src={logo} alt="Tee Gee Logo" />*/}
                {/*    </Link>*/}
                {/*</div>*/}

                {/* Render the logo conditionally based on mobileMenuOpen */}

                {/*<div className="flex">*/}
                {/*    <button*/}
                {/*        type="button"*/}
                {/*        className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 ${mobileMenuOpen ? 'hidden' : ''}`}*/}
                {/*        onClick={() => setMobileMenuOpen(true)}*/}
                {/*    >*/}
                {/*        <span className="sr-only">Open main menu</span>*/}
                {/*        <img src={hamburger} alt="hamburger icon" className={`${scrolled ? 'h-[45px]' : 'h-[80px]'} w-auto`} />*/}
                {/*        /!*<Bars3Icon className="h-6 w-6" aria-hidden="true" />*!/*/}
                {/*    </button>*/}
                {/*</div>*/}

                <div className="flex  items-end">
                    <button
                        type="button"
                        className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 ${mobileMenuOpen ? 'hidden' : ''}`}
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className={`${scrolled ? 'h-[45px]' : 'h-[65px]'} w-auto`} viewBox="0 0 100 100">
                            <polygon points="0,0 100,0 100,100 0,100" fill="transparent" />
                            <line x1="10" y1="20" x2="85" y2="20" stroke="#838587" strokeWidth="7" strokeLinecap="round" />
                            <line x1="10" y1="40" x2="85" y2="40" stroke="#838587" strokeWidth="7" strokeLinecap="round" />
                            <line x1="10" y1="60" x2="65" y2="60" stroke="#838587" strokeWidth="7" strokeLinecap="round" />
                        </svg>
                    </button>
                </div>







            </nav>
            <Transition.Root show={mobileMenuOpen} as={Fragment}>
                <Dialog as="div" className="" onClose={setMobileMenuOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition duration-[900ms] ease-in-out"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition duration-[900ms] ease-in-out"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <div className="fixed inset-0 z-10" onClick={() => setMobileMenuOpen(false)} />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition duration-[900ms] ease-in-out"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition duration-[900ms] ease-in-out"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full  backdrop-blur-lg bg-black/80 px-6 pt-6 sm:max-w-[73rem] sm:ring-5 sm:ring-gray-900/10">
                            <div className="flex items-center justify-between pr-8">
                                <a href="#" className="-m-1.5 p-1.5">
                                    <span className="sr-only">Tee Gee Agency (Pvt) Ltd</span>
                                    <img
                                        className="h-8 w-auto"
                                        src={logo}
                                        alt="TeeGee Logo"
                                    />
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <img src={closeBtn} className="h-10 w-10 rotate-90" aria-hidden="true"  alt="Close button"/>
                                </button>
                            </div>
                            <div className="mt-6 flow-root text-neutral-200 uppercase">
                                <div className="-my-6 divide-y divide-gray-500/10 ">
                                    <div className="py-6 flex justify-between pr-12 mb-3 md:mb-0">
                                        {/* First column */}
                                        <div className="w-full md:w-3/12 px-2 py-2 text-neutral-200 block max-h-[30vh] md:max-h-[45vh] h-auto md:h-[45vh]">
                                            <div>
                                                <div className="flex md:w-4/12 text-xs font-bold">
                                                    <div className="mr-2">
                                                        <span className="hover:text-pantone">AR</span>
                                                    </div>
                                                    <div className="mx-2">
                                                        <span>|</span>
                                                    </div>
                                                    <div className="mx-2">
                                                        <span className="hover:text-pantone">EN</span>
                                                    </div>
                                                    <div className="ml-2">
                                                        <GlobeAltIcon className="w-[20px] h-[20px] "/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="block mt-4 font-black text-2xl md:text-4xl uppercase ">
                                                    <Link to="/" className="hover:text-pantone">
                                                        <div>
                                                            <h2>Home</h2>
                                                        </div>
                                                    </Link>

                                                    <Link to="/about-us" className="hover:text-pantone">
                                                        <div>
                                                            <h2>About Us</h2>
                                                        </div>
                                                    </Link>

                                                    <Link to="/services" className="hover:text-pantone md:hidden md:block">
                                                        <div className=''>
                                                            <h2>Services</h2>
                                                        </div>
                                                    </Link>

                                                    {/*<Link to="/blog" className="hover:text-pantone">*/}
                                                    {/*    <div>*/}
                                                    {/*        <h2>Blog</h2>*/}
                                                    {/*    </div>*/}
                                                    {/*</Link>*/}

                                                    <Link to="/products" className="hover:text-pantone">
                                                        <div>
                                                            <h2>Products</h2>
                                                        </div>
                                                    </Link>

                                                    <Link to="/our-work" className="hover:text-pantone">
                                                        <div>
                                                            <h2>Our Work</h2>
                                                        </div>
                                                    </Link>

                                                    <Link to="/contact-us" className="hover:text-pantone">
                                                        <div>
                                                            <h2>Reach Us</h2>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>


                                        {/* Second column */}
                                        {/*<div className="w-3/12 pt-12 ">*/}



                                        {/*</div>*/}




                                        {/*<div className="w-3/12 pt-12">*/}
                                        {/*    <Link to="/services">*/}
                                        {/*        <div className="hidden md:flex justify-start items-center text-4xl">*/}
                                        {/*            <div>*/}
                                        {/*                <h1 className="font-black hover:text-pantone">Our Services</h1>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </Link>*/}
                                        {/*        <div className="hidden md:grid grid-cols-1 gap-3 column-gap-1 text-xl mt-4 font-black uppercase justify-items-start">*/}
                                        {/*            <Link to="/design_services">*/}
                                        {/*                <div className="flex items-center">*/}
                                        {/*                    /!*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*!/*/}
                                        {/*                    <h1 className="hover:text-pantone pl-4"> Design Services</h1>*/}
                                        {/*                </div>*/}
                                        {/*            </Link>*/}
                                        {/*            <Link to="/digital_print">*/}
                                        {/*                <div className="flex items-center">*/}
                                        {/*                    /!*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*!/*/}
                                        {/*                    <h1 className="hover:text-pantone pl-4"> Digital Printing</h1>*/}
                                        {/*                </div>*/}
                                        {/*            </Link>*/}
                                        {/*            <Link to="/services/branding-services">*/}
                                        {/*                <div className="flex items-center">*/}
                                        {/*                    /!*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*!/*/}
                                        {/*                    <h1 className="hover:text-pantone pl-4"> Branding Services </h1>*/}
                                        {/*                </div>*/}
                                        {/*            </Link>*/}
                                        {/*            <Link to="/events_exhibitions">*/}
                                        {/*                <div className="flex items-center">*/}
                                        {/*                    /!*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*!/*/}
                                        {/*                    <h1 className="hover:text-pantone pl-4"> Event Management</h1>*/}
                                        {/*                </div>*/}
                                        {/*            </Link>*/}
                                        {/*        </div>*/}
                                        {/*</div>*/}


                                        {/* Third column */}

                                        {/*<div className="w-5/12 min-h-full flex">*/}

                                        {/*</div>*/}

                                        <div className="w-5/12 pt-12">
                                            <Link to="/services"> {/* Add Link component with "to" prop pointing to "/contact-us" */}
                                                <div className="hidden md:flex justify-start items-center text-4xl">
                                                    <div>
                                                        <h1 className="font-black hover:text-pantone">Services</h1>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to=""> {/* Add Link component with "to" prop pointing to "/contact-us" */}
                                                <div className="hidden md:flex justify-start items-center text-4xl">
                                                    <div>
                                                        <h1 className="font-black hover:text-pantone">Case Studies</h1>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to=""> {/* Add Link component with "to" prop pointing to "/contact-us" */}
                                                <div className="hidden md:flex justify-start items-center text-4xl">
                                                    <div>
                                                        <h1 className="font-black hover:text-pantone">Gallery</h1>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to=""> {/* Add Link component with "to" prop pointing to "/contact-us" */}
                                                <div className="hidden md:flex justify-start items-center text-4xl">
                                                    <div>
                                                        <h1 className="font-black hover:text-pantone">FAQ</h1>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div className="hidden md:hidden grid-cols-1  column-gap-1 text-3xl mt-4 font-black uppercase justify-items-start">
                                                <Link to="/case_studies">
                                                    <div className="flex items-center">
                                                        {/*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*/}
                                                        <h1 className="hover:text-pantone ">Case Studies</h1>
                                                    </div>
                                                </Link>
                                                <Link to="/gallery_page">
                                                    <div className="flex items-center">
                                                        {/*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*/}
                                                        <h1 className="hover:text-pantone "> Gallery</h1>
                                                    </div>
                                                </Link>
                                                <Link to="/Faq">
                                                    <div className="flex items-center">
                                                        {/*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*/}
                                                        <h1 className="hover:text-pantone "> FAQ </h1>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                            {/*<div className="flex pt-4  justify-around ">*/}
                                            {/*    <div className="block min-w-6/12 w-6-12 ">*/}
                                            {/*        <div className=" hidden  md:flex justify-center items-center text-3xl ">*/}
                                            {/*            <div>*/}
                                            {/*                <h1 className="font-black">Insights</h1>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="hidden md:flex justify-center items-center">*/}
                                            {/*            <div className="grid grid-cols-2 gap-4 column-gap-4 text-xl mt-4 font-black uppercase">*/}
                                            {/*                <div className="flex items-center">*/}
                                            {/*                    <img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*/}
                                            {/*                    <h1 className="hover:text-pantone">Gallery</h1>*/}
                                            {/*                </div>*/}
                                            {/*                <div className="flex items-center">*/}
                                            {/*                    <img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*/}
                                            {/*                    <h1 className="hover:text-pantone">Case Studies</h1>*/}
                                            {/*                </div>*/}
                                            {/*                <div className="flex items-center">*/}
                                            {/*                    <img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true"  alt="bullet"/>*/}
                                            {/*                    <h1 className="hover:text-pantone">FAQ</h1>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}

                                            {/*    <div className="hidden sm:block">*/}
                                            {/*        <div className="h-full border-l border-gray-500/10"></div>*/}
                                            {/*    </div>*/}



                                            {/*    <div className="block min-w-6/12 pr-8 ">*/}
                                            {/*        <div className="grid grid-cols-[repeat(2,max-content)] gap-3 column-gap-1 text-md mt-4 font-black">*/}
                                            {/*            <div className="divide-x divide-gray-500/10">*/}
                                            {/*                <button*/}
                                            {/*                    className="flex items-center uppercase hover:bg-gradient-to-br from-instayellow via-instapink to-instapurple px-4 py-2 rounded-full ">*/}
                                            {/*                    <Instagram className="w-5 h-5 text-white mr-3 "/>*/}
                                            {/*                    <h1 className="">Instagram</h1>*/}
                                            {/*                </button>*/}
                                            {/*            </div>*/}
                                            {/*            <div>*/}
                                            {/*                <button*/}
                                            {/*                    className="flex items-center uppercase hover:bg-whatsappgreen px-4 py-2 rounded-full ">*/}
                                            {/*                    <Whatsapp className="w-5 h-5 text-white mr-3"/>*/}
                                            {/*                    <h1 className="">Whatsapp</h1>*/}
                                            {/*                </button>*/}
                                            {/*            </div>*/}
                                            {/*            <div>*/}
                                            {/*                <button*/}
                                            {/*                    className="flex items-center uppercase hover:bg-pantone px-4 py-2 rounded-full ">*/}
                                            {/*                    <PhoneIcon className="w-5 h-5 text-white mr-3"/>*/}
                                            {/*                    <h1 className="">Call Now</h1>*/}
                                            {/*                </button>*/}
                                            {/*            </div>*/}
                                            {/*            <div>*/}
                                            {/*                <button*/}
                                            {/*                    className="flex items-center uppercase hover:bg-facebookblue px-4 py-2 rounded-full ">*/}
                                            {/*                    <Facebook className="w-5 h-5 text-white mr-3"/>*/}
                                            {/*                    <h1 className="">Facebook</h1>*/}
                                            {/*                </button>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                    <div className="flex pt-2 justify-around flex-wrap">
                                        <div className="w-full md:w-5/12 md:hidden">
                                            <div className="flex justify-center items-center text-2xl md:text-3xl">
                                                <div>
                                                    <h1 className="font-black">Insights</h1>
                                                </div>
                                            </div>

                                            <div className="flex justify-center items-center">
                                                <div className="grid grid-cols-2 gap-4 column-gap-4 text-lg md:text-xl mt-4 font-black uppercase ">
                                                    <div className="flex items-center">
                                                        {/*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true" alt="bullet"/>*/}
                                                        <h1 className="hover:text-pantone">Gallery</h1>
                                                    </div>
                                                    <Link to="/case_studies">
                                                    <div className="flex items-center">
                                                        {/*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true" alt="bullet"/>*/}
                                                        <h1 className="hover:text-pantone">Case Studies</h1>
                                                    </div>
                                                    </Link>
                                                    <div className="flex items-center">
                                                        {/*<img src={closeBtn} className="h-4 w-5 mr-2" aria-hidden="true" alt="bullet"/>*/}
                                                        <h1 className="hover:text-pantone">FAQ</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-5/12 min-h-full flex">
                                            {/* Photo Collage */}
                                            <div className="hidden md:flex w-6/12">
                                                <div className="block min-w-full">
                                                    <div className="min-w-full">
                                                        <img src={image4} className="object-cover w-full h-full" alt="event image" />
                                                    </div>
                                                    <div className="min-w-full">
                                                        <img src={image1} className="object-cover w-full h-full" alt="event image" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="hidden md:flex w-6/12 min-h-full">
                                                <div className="block min-w-full min-h-full">
                                                    <div className="min-h-6/12 min-w-full bg-red-500">
                                                        <img src={image3} className="object-cover w-full h-full" alt="event image" />
                                                    </div>
                                                    <div className="min-h-6/12 min-w-full bg-red-500">
                                                        <img src={image2} className="object-cover w-full h-full" alt="event image" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="w-full md:w-1/12 sm:w-auto sm:block  md:inline-flex justify-center items-center">
                                            <div className="h-full border-l border-gray-500/10"></div>
                                        </div>

                                        <div className="w-full md:w-1/12 md:inline-flex mt-5 md:hidden">
                                            <div className="h-full border-y border-gray-500/10"></div>
                                        </div>

                                        <div className="w-full md:w-5/12">
                                            <div className="grid grid-cols-[repeat(2,max-content)] gap-3 column-gap-1 text-md mt-[40px] md:mt-[80px] font-black justify-center md:justify-start items-center">

                                            <div className="divide-x divide-gray-500/10">
                                                    <button className="flex items-center uppercase from-instayellow via-instapink to-instapurple md:hover:bg-gradient-to-br md:hover:from-instayellow md:hover:via-instapink md:hover:to-instapurple hover:bg-gradient-to-br hover:from-instayellow hover:via-instapink hover:to-instapurple px-4 py-2 rounded-full">
                                                        <Instagram className="w-5 h-5 text-white mr-3" />
                                                        <h1 className="">Instagram</h1>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="flex items-center uppercase md:hover:bg-whatsappgreen hover:bg-whatsappgreen px-4 py-2 rounded-full">
                                                        <Whatsapp className="w-5 h-5 text-white mr-3" />
                                                        <h1 className="">Whatsapp</h1>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="flex items-center uppercase md:hover:bg-pantone hover:bg-pantone px-4 py-2 rounded-full">
                                                        <PhoneIcon className="w-5 h-5 text-white mr-3" />
                                                        <h1 className="">Call Now</h1>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="flex items-center uppercase md:hover:bg-facebookblue hover:bg-facebookblue px-4 py-2 rounded-full">
                                                        <Facebook className="w-5 h-5 text-white mr-3" />
                                                        <h1 className="">Facebook</h1>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-[repeat(2,max-content)] gap-3 column-gap-1 text-md font-black justify-end items-center">
                                                <div className="divide-x divide-gray-500/10">
                                                </div>
                                                {/*<div style={{*/}
                                                {/*    color: '#000', */}
                                                {/*    fontSize: '78px', */}
                                                {/*    textShadow: '-1px -1px 0 #6dd1e8, 1px -1px 0 #6dd1e8, -1px 1px 0 #6dd1e8, 1px 1px 0 #6dd1e8' */}
                                                {/*}}>*/}
                                                {/*    Since 2015*/}
                                                {/*</div>*/}

                                                <h3 className="font-semibold uppercase py-5 hidden md:block" style={{
                                                    fontSize: '38px',
                                                    // background: 'radial-gradient(circle, rgba(22,162,227,1) 0%, rgba(196,70,238,1) 82%)',
                                                    WebkitBackgroundClip: 'text',
                                                    backgroundClip: 'text',
                                                    WebkitTextFillColor: 'transparent',
                                                    textFillColor: 'transparent',
                                                    WebkitTextStroke: '2px #69c9e1',
                                                    textStroke: '2px #69c9e1',
                                                    textAlign: 'right' // Add this line for right alignment
                                                }}>
                                                    Since 2015
                                                </h3>
                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>
        </header>
    )
}
