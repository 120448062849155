import React, { useState, useEffect } from 'react';

const ImageSlider = ({ images, interval = 3000, onGradientChange }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => {
                const newIndex = prevIndex === images.length - 1 ? 0 : prevIndex + 1;
                const newGradient = images[newIndex].gradient;
                onGradientChange(newGradient); // Pass the new gradient to the parent component
                return newIndex;
            });
        }, interval);

        return () => clearInterval(intervalId);
    }, [images, interval, onGradientChange]);

    return (
        <div className="relative">
            <img
                src={images[currentImageIndex].url}
                alt={`Slide ${currentImageIndex + 1}`}
                className="w-full h-auto transition-opacity duration-1000 ease-in-out"
            />
        </div>
    );
};

export default ImageSlider;
