import React from 'react';


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';







import Marquee from "react-fast-marquee";

import logo1 from '../assets/bx-logo.png';

import image1 from '../../images/clients/doha-golf.webp';
import image2 from '../../images/clients/alkass.webp';
import image3 from '../../images/clients/qatar-airways.webp';
import image4 from '../../images/clients/supreme-committee.webp';
import image5 from '../../images/clients/qatar-racing.webp';
import image6 from '../../images/clients/losberger-de-boer.webp';




const HomeClient = () => {
    // Define an array of logo objects with image URLs and corresponding links
    const logos = [
        { url: logo1, link: 'https://example.com/link1' },
        { url: logo1, link: 'https://example.com/link2' },
        { url: logo1, link: 'https://example.com/link3' },
        { url: logo1, link: 'https://example.com/link1' },
        { url: logo1, link: 'https://example.com/link2' },
        { url: logo1, link: 'https://example.com/link3' },
        // Add more logo objects as needed
    ];

    // Settings for the slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 1000, // Set autoplay speed in milliseconds
    };

    return (
        <div>





            <div className="  bg-opacity-20 bg-neutral-900 ">
                <div className=" max-w-7xl  mx-auto  ">
                <div className="content lg:flex md:flex ">
                    <div className="content-text lg:w-1/3 md:w-1/3 sm:w-1 h-auto text-gray-200 text-xl text-center align-middle py-3 backdrop-filter backdrop-blur-lg bg-opacity-20">

                        <div className="text-start  ">
                            <h1 className="font-bold text-6xl">
                                <span style={{backgroundImage: 'linear-gradient(to right, #8a2be2, #00bfff)', WebkitBackgroundClip: 'text', color: 'transparent'}}>Clients Who Have</span>
                            </h1>
                            <h1 className="font-bold text-6xl">
                                <span style={{backgroundImage: 'linear-gradient(to right, #8a2be2, #00bfff)', WebkitBackgroundClip: 'text', color: 'transparent'}}>Trust On Us</span>
                            </h1>

                            <h1 className="text-[165px] text-white font-bold"style={{

                                // background: 'radial-gradient(circle, rgba(22,162,227,1) 0%, rgba(196,70,238,1) 82%)',
                                WebkitBackgroundClip: 'text',
                                backgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                textFillColor: 'transparent',
                                WebkitTextStroke: '2px #1e9fe4',
                                textStroke: '2px #e17426'
                            }}>
                                90+</h1>

                        
                        </div>


                    </div>


                    <div className="content-slider lg:w-2/3 md:w-2/3 sm:w-1 flex justify-center items-center">
                        <Marquee>
                            <img className="me-2 w-[200px] h-[200px]" src={image1} alt="Doha Golf Club"/>
                            <img className="me-2 w-[200px] h-[200px]" src={image2} alt="Alkass"/>
                            <img className="me-2 w-[200px] h-[200px]" src={image3} alt="Qatar Airways"/>
                            <img className="me-2 w-[200px] h-[200px]" src={image4} alt="Supreme Committee"/>
                            <img className="me-2 w-[200px] h-[200px]" src={image5} alt="Qatar Racing"/>
                            <img className="me-2 w-[200px] h-[200px]" src={image6} alt="Losberger De Boer"/>

                        </Marquee>




                    </div>

                </div>
            </div>
            </div>
        </div>
    );
};

export default HomeClient;
