import React from "react";
import Navbar from "../../components/common/navbar";
import Reach_us from "../../components/common/reach_us";
import Footer from "../../components/common/footer";

function tradeShowDisplay() {
  return (
      <div className="bg-neutral-950">
          <Navbar/>
          <div className="mx-auto mb-24">
              <section>
                  <div
                      className="relative  h-[400px] lg:h-[600px] w-screen overflow-hidden bg-cover bg-no-repeat bg-[url('https://images.unsplash.com/photo-1627384113710-424c9181ebbb?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]">
                      <div
                          className="absolute max-h-[400px] lg:max-h-[600px] inset-0 backdrop-blur-none bg-black/60 flex justify-center items-center">
                          <div className="text-center">
                              <h1 className="text-neutral-200 text-3xl font-black uppercase">
                                  Trade Show Display
                              </h1>
                          </div>
                      </div>
                  </div>
                  <div className="container px-1 md:px-12">
                      <div
                          className="block h-full rounded-lg bg-[hsla(0,0%,100%,0.8)] px-4 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,20%,0.3)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] ">
                          <div className={'text-white text-justify mx-32'}>
                              <p className={'font-extralight '}>
                                  <span className={'text-2xl font-bold text-pantone '}>Step</span> into the realm of
                                  brand transformation
                                  with our
                                  pioneering branding company. At the heart of our mission lies
                                  a commitment to crafting compelling brand narratives that
                                  resonate deeply with audiences. We offer a diverse range of
                                  Trade Show Display meticulously tailored to elevate your
                                  brand's identity and presence. From strategic brand
                                  consultancy and logo design to comprehensive brand identity
                                  development, packaging design, and immersive brand
                                  experiences, we're dedicated to delivering solutions that
                                  captivate and inspire. Our team of seasoned professionals
                                  collaborates closely with clients to understand their ethos,
                                  values, and aspirations, ensuring that every aspect of their
                                  brand reflects authenticity and leaves a lasting impression.
                                  With creativity, strategy, and innovation at our core, we're
                                  here to guide you on your journey towards brand excellence.
                              </p>
                              <div className=" h-screen h-full  sm:py-8 lg:py-12">
                                  <div className="mx-auto max-w-screen-2xl ">
                                      <div className="mb-4 flex items-center justify-between gap-8 sm:mb-8 md:mb-12">
                                          <div className="flex items-center gap-12">
                                              <h2 className="text-2xl font-bold text-pantone lg:text-3xl dark:text-white">Gallery</h2>

                                              <p className="hidden max-w-screen-sm text-gray-500 dark:text-gray-300 md:block">
                                                  Some Samples of the Service Provided
                                              </p>
                                          </div>
                                      </div>

                                      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">

                                          <a href="#"
                                             className="group relative flex h-48 items-end overflow-hidden rounded-lg  shadow-lg md:h-80">
                                              <img
                                                  src="https://images.unsplash.com/photo-1534670007418-fbb7f6cf32c3?q=80&w=988&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                  loading="lazy" alt="Photo by Minh Pham"
                                                  className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"/>

                                              <div
                                                  className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                                              </div>

                                              <span
                                                  className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">1</span>
                                          </a>

                                          <a href="#"
                                             className="group relative flex h-48 items-end overflow-hidden rounded-lg  shadow-lg md:col-span-2 md:h-80">
                                              <img
                                                  src="https://images.unsplash.com/photo-1416339134316-0e91dc9ded92?q=80&w=1074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                  loading="lazy" alt="Photo by Magicle"
                                                  className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"/>

                                              <div
                                                  className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                                              </div>

                                              <span
                                                  className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">2</span>
                                          </a>

                                          <a href="#"
                                             className="group relative flex h-48 items-end overflow-hidden rounded-lg  shadow-lg md:col-span-2 md:h-80">
                                              <img
                                                  src="https://images.unsplash.com/photo-1633533452148-a9657d2c9a5f?q=80&w=1031&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                  loading="lazy" alt="Photo by Martin Sanchez"
                                                  className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"/>

                                              <div
                                                  className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                                              </div>

                                              <span
                                                  className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">3</span>
                                          </a>

                                          <a href="#"
                                             className="group relative flex h-48 items-end overflow-hidden rounded-lg  shadow-lg md:h-80">
                                              <img
                                                  src="https://plus.unsplash.com/premium_photo-1661914978519-52a11fe159a7?q=80&w=1035&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                  loading="lazy" alt="Photo by Lorenzo Herrera"
                                                  className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"/>

                                              <div
                                                  className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                                              </div>

                                              <span
                                                  className="relative ml-4  inline-block text-sm text-white md:ml-5 md:text-lg">4</span>
                                          </a>

                                      </div>
                                  </div>
                              </div>
                              <div className={'w-full flex justify-center items-center mt-[-130px]'}>
                                  <button className={'bg-pantone rounded-full p-2'}>Get Trade Show Display</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div>
          <Reach_us/>
          <Footer/>
      </div>
  );
}

export default tradeShowDisplay;
