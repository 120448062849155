import React, {useEffect} from 'react';


import "../css/services.css";
import AOS from "aos";
import Navbar from "../components/common/navbar";
import Footer from "../components/common/footer";
import {Link, useNavigate} from "react-router-dom";
import Reach_us from "../components/common/reach_us";
import SplitType from "split-type"
import {gsap} from "gsap";
import { FaArrowRight } from "react-icons/fa";

import serviceComponents from "../components/common/serviceComponents";


const Services = () => {



    useEffect(() => {
        let texts = document.querySelectorAll('#name');

        texts.forEach((text, index) => {
            new SplitType(text);
        });



    }, []);

    useEffect(() => {
        const parents = document.querySelectorAll('#parent');

        const animateChars = (words) => {
            words.forEach((word) => {
                const chars = word.querySelectorAll('.char');

                // Set initial styles
                gsap.set(chars, {opacity: 0, y: '100%' });

                // Animate characters
                chars.forEach((char, index) => {
                    gsap.to(char, {
                        duration: 0.2,
                        opacity: 1,
                        y: '0',
                        delay: index * 0.07, // Adjust delay timing as needed
                        ease: 'power2.out' // Adjust easing function as needed
                    });
                });
            });
        };

        const reverseCharsAnimation = (words) => {
            words.forEach((word) => {
                const chars = word.querySelectorAll('.char');

                // Reverse animation
                chars.forEach((char) => {
                    gsap.to(char, {
                        duration: 0.3,
                        opacity: 1,
                        y: '0',
                        ease: 'power2.inOut' // Adjust easing function as needed
                    });
                });
            });
        };

        // Attach event listeners to each parent div
        parents.forEach((parent) => {
            const words = parent.querySelectorAll('.word');
            parent.addEventListener('mouseenter', () => animateChars(words));
            parent.addEventListener('mouseleave', () => reverseCharsAnimation(words));
        });

        // Cleanup event listeners
        return () => {
            parents.forEach((parent) => {
                const words = parent.querySelectorAll('.word');
                parent.removeEventListener('mouseenter', () => animateChars(words));
                parent.removeEventListener('mouseleave', () => reverseCharsAnimation(words));
            });
        };
    }, []);






    const gradientStyle = {
        background: "bg-neutral-950",
        // backgroundImage: "radial-gradient(at 76.0% 1.0%, hsl(30, 87%, 10%) 0px, transparent 50%), radial-gradient(at 59.5% 52.3%, hsl(222, 8%, 32%) 0px, transparent 50%)",
        height: 'auto'
        // Add other styles if needed
    };
    const boxStyle = {
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    };

    const hoverStyle = {
        transform: 'scale(1.05)', // Increase size on hover
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)', // Add box shadow on hover
    };



    useEffect(() => {
        AOS.init({
            duration: 1500, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Add smooth scrolling effect
        });
    };


    const renderServices = () => {
        return(
            <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3'}>
                {serviceComponents.filterData.map((item, index) => (



                    <div onClick={() => {navigate(`${item.path}`); scrollToTop();}} >
                        <div key={index} className={'p-12'}>

                            <div id={'parent'} className={'group relative overflow-hidden'}>
                                <div className={''}>
                                    <img
                                        className={'w-[648px] h-[382px] object-cover grayscale group-hover:grayscale-0  duration-300 group-hover:scale-110 transition-transform'}
                                        src={item.img} alt={item.type}/>
                                </div>

                                <div className={'absolute text-gray-300 group-hover:text-white group-hover: top-0 right-0 p-12'}>
                                    < FaArrowRight className={'text-2xl transform group-hover:-rotate-45 duration-300'} />
                                </div>

                                <div
                                    className={'absolute px-12 py-14 bottom-0 mt-[-50px] text-white flex flex-col'}>
                                    <h1
                                        id={'name'}
                                        style={{textShadow: '1px 1px 2px black, 0 0 1em black, 0 0 0.2em black'}}
                                        className={'mb-2 block text-gray-300 group-hover:text-white group-hover:hidden opacity-100 text-2xl font-bold '}>{item.type}</h1>
                                    <h1
                                        id={'name'}
                                        className={'mb-2 hidden text-gray-300 group-hover:text-white group-hover:block text-2xl font-bold '}>{item.type}</h1>
                                    <p
                                        style={{textShadow: '1px 1px 2px black, 0 0 1em black, 0 0 0.2em black'}}
                                        className={'text-gray-300 group-hover:text-white'}>{item.title}</p>
                                </div>
                            </div>


                        </div>
                    </div>

                ))}
            </div>

        )
    }


    return (

        <div className="bg-neutral-950">


        <Navbar/>


        <div className="  mx-auto ">
            <section className=" ">
                <div
                    className="relative h-[400px] overflow-hidden bg-cover bg-no-repeat bg-[url('https://b-expression.com/wp-content/uploads/2019/12/qatar.jpg')]">
                    <div
                        className="absolute max-h-[400px] inset-0 backdrop-blur-none bg-black/60 flex justify-center items-center">
                        <div className="text-center">
                            <h1 className="text-neutral-200 text-3xl font-black uppercase">Services</h1>
                        </div>
                    </div>

                </div>

                <div className="container px-1 md:px-12">
                    <div className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-2 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,20%,0.3)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] ">


                        <div className=" " style={gradientStyle}>

                            <div className="covering ">

                                {renderServices()}


                            </div>

                        </div>


                    </div>


                </div>
            </section>
        </div>




        <Reach_us/>


        <Footer/>


    </div>

    );
};

export default Services;
