
import Image from '../components/assets/img1.jpg';

import {useEffect, useState} from "react";
import AOS from "aos"; // Replace with the actual path to your CEO's image
import 'aos/dist/aos.css';
import Navbar from "../components/common/navbar";
import Footer from "../components/common/footer";
import filter from "../components/common/filter";
import Reach_us from "../components/common/reach_us";
import ReachUs from "../components/common/reach_us";
function About() {

    const [selectedFilter, setSelectedFilter] = useState(1);

    const handleFilterButtonClick = (filterType) => {
        setSelectedFilter(filterType)
    };



    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);

    return (
        <div className="bg-neutral-950">
            <Navbar />
            <div className="mx-auto bg-neutral-950">
                <section className=" ">
                    {/* Your background image */}
                    <div className="relative h-[400px] overflow-hidden bg-cover bg-no-repeat bg-[url('https://b-expression.com/wp-content/uploads/2022/07/01.jpg')]">
                        <div className="absolute inset-0 backdrop-blur-none bg-black/60 flex justify-center items-center">
                            <div className="text-center">
                                <h1 className="text-neutral-200 text-3xl font-black uppercase">About Us</h1>
                            </div>
                        </div>

                    </div>


                    <div className="flex flex-col w-100 md:w-32 lg:w-48 min-h-1 max-h-500 container max-w-[1380px] px-6 md:px-12 text-white" >
                        <div
                            className="font-bold !h-10 resize-y align-top bg-neutral-950/60 px-2 py-2.5 -mt-[90px] backdrop-blur-[30px] flex flex-row md:flex-row ">
                            <div className="">
                                <button
                                    className={`px-2  uppercase text-xs  ${selectedFilter === 1 ? 'text-pantone' : 'hover:text-pantone focus:pantone focus:underline-offset-8'}`}
                                    onClick={() => handleFilterButtonClick(1)}>
                                    Our Company
                                </button>
                            </div>
                            <div className=" ">
                                <button
                                    className={`px-2 uppercase text-xs  ${selectedFilter === 2 ? 'text-pantone' : 'hover:text-pantone focus:pantone focus:underline-offset-8'}`}
                                    onClick={() => handleFilterButtonClick(2)}>
                                    Our Team
                                </button>
                            </div>
                            <div className="">
                                <button
                                    className={`px-2   uppercase text-xs  ${selectedFilter === 3 ? 'text-pantone' : 'hover:text-pantone focus:pantone focus:underline-offset-8'}`}
                                    onClick={() => handleFilterButtonClick(3)}>
                                    Our Facilities
                                </button>
                            </div>
                        </div>
                        <div
                            className="mb-3 block rounded-b-lg px-6 py-12 md:py-16 bg-gradient-to-b from-neutral-950/60 to-neutral-950 md:px-12 -mt[80px] backdrop-blur-[30px] flex-col md:flex-row">

                            {/* About Us content */}

                            {filter.filterData.map((item, index) => {
                                if ((selectedFilter === '' || item.id === selectedFilter)){
                                    return (
                                        <div className="flex flex-col md:flex-row">
                                            <div className="mb-6 md:pr-8 md:w-1/2" data-aos="fade-up">
                                                <h2 className="text-3xl font-bold mb-4" data-aos="fade-left">{item.title}</h2>
                                                <p className="mb-6 text-left">{item.desc}</p>
                                            </div>
                                            {/* Image */}
                                            <div key={index} className="md:w-1/2" data-aos="fade-left">
                                                <img
                                                    src={item.img} // Replace with the actual path to your image
                                                    alt="About Us Image"
                                                    className="rounded-lg mt-12 w-full h-auto"
                                                />
                                            </div>
                                        </div>
                                    )
                                    return null;
                                }
                            })}


                        </div>


                    </div>
                    <div className="h-[3vh] min-w-full w-full flex flex-end justify-end">
                        <div className="w-6/12 h-full min-h-full">
                        <div className="bg-pantone h-full w-full min-w-full min-h-full"></div>
                        </div>

                    </div>
                </section>



                <section>
                    <div className="text-green-950 h-[55vh]">
                        <div className="bg-cover h-full bg-neutral-950 bg-no-repeat bg-[url('https://res.cloudinary.com/fortyfournorth/image/upload/v1637269046/The%20Look%20Company%20%28Staging%29/gp3nw6twccqbs27jw1iy.svg')]">
                            <div className="flex flex-col w-100 md:w-32 lg:w-48 min-h-1 max-h-500 container max-w-7xl px-6 md:px-12">
                                <ReachUs/>

                            </div>
                        </div>
                    </div>

                </section>
            </div>
            <Footer />
        </div>
    );
}

export default About;
