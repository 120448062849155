import React, {useEffect, useState} from 'react';
import {
    Ripple,
    initTWE,
} from "tw-elements";
import AOS from "aos";
import Navbar from "../common/navbar";
import Footer from "../common/footer";
import Reach_us from "../common/reach_us";
import case_studies from "../common/case_studies";


function Digital_print() {

    const [hovered, setHovered] = useState(true);

    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);

    initTWE({ Ripple });

    return (
        <div className="bg-neutral-950">
            <Navbar/>
            <div className="  mx-auto ">
                <section className=" ">
                    <div
                        className="relative h-[400px] overflow-hidden bg-cover bg-no-repeat bg-[url('https://b-expression.com/wp-content/uploads/2019/12/qatar.jpg')]">
                        <div
                            className="absolute max-h-[400px] inset-0 backdrop-blur-none bg-black/60 flex justify-center items-center">
                            <div className="text-center">
                                <h1 className="text-neutral-200 text-3xl font-black uppercase">Case Studies</h1>
                            </div>
                        </div>

                    </div>
                    <div className="container px-6 md:px-12 max-w-[1380px]">
                        <div
                            className=" rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,20%,0.3)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] ">
                            <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                                {case_studies.caseData.map((item, index) => {
                                    return (
                                        <div class="root"
                                             className="group min-h-sm transition-all duration-500 max-w-xl relative flex items-center  justify-center"
                                             data-aos="fade-left">
                                            <div
                                                className="bg-pantone items-center justify-center rounded-lg mb-4 md:ml-4">

                                                <img alt="news"
                                                     className=" group-hover:opacity-20  bottom-0 transition-all rounded-lg"
                                                     src={item.img}/>
                                            </div>
                                            <div className="absolute flex flex-col">
                                                <p data-aos='fade-right' className="hidden font-bold text-xs   group-hover:block   text-center  mb-1 max-w-[200px] uppercase text-white">{item.title}</p>
                                                <button
                                                    className="hidden text-xs border-2 py-2 rounded-2xl group-hover:cursor-pointer  group-hover:block text-neutral-200">Read
                                                    More
                                                </button>
                                            </div>

                                        </div>

                                    )
                                })}
                                <div
                                    className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex ">
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">

                                    </div>

                                </div>

                            </section>

                        </div>
                    </div>
                </section>


                {/*<section className="relative overflow-hidden py-28 px-4 bg-gray-900 md:px-8">*/}
                {/*    <div className="w-full h-full rounded-full bg-gradient-to-r from-[#58AEF1] to-pink-500 absolute -top-12 -right-14 blur-2xl opacity-10"></div>*/}
                {/*    <div className="max-w-xl mx-auto text-center relative">*/}
                {/*        <div className="py-4" data-aos="zoom-in">*/}
                {/*            <h3 className="text-3xl text-gray-200 font-semibold md:text-4xl">*/}
                {/*                You can type any Message Here*/}
                {/*            </h3>*/}
                {/*            <p className="text-gray-300 leading-relaxed mt-3">*/}
                {/*                Nam erat risus, sodales sit amet lobortis ut, finibus eget metus. Cras aliquam ante ut tortor posuere feugiat. Duis sodales nisi id porta lacinia.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*        /!*<div className="mt-5 items-center justify-center gap-3 sm:flex">*!/*/}
                {/*        /!*    <a*!/*/}
                {/*        /!*        href="javascript:void(0)"*!/*/}
                {/*        /!*        className="block w-full mt-2 py-2.5 px-8 text-gray-700 bg-white rounded-md duration-150 hover:bg-gray-100 sm:w-auto"*!/*/}
                {/*        /!*    >*!/*/}
                {/*        /!*        Try It Out*!/*/}
                {/*        /!*    </a>*!/*/}
                {/*        /!*    <a*!/*/}
                {/*        /!*        href="javascript:void(0)"*!/*/}
                {/*        /!*        className="block w-full mt-2 py-2.5 px-8 text-gray-300 bg-gray-700 rounded-md duration-150 hover:bg-gray-800 sm:w-auto"*!/*/}
                {/*        /!*    >*!/*/}
                {/*        /!*        Get Started*!/*/}
                {/*        /!*    </a>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}
                {/*</section>*/}



            </div>

            {/*1*/}
            {/*<section className="container mx-auto">*/}



                {/*<div className="relative  ">*/}
                {/*    <div className="absolute inset-0 -z-1">*/}
                {/*        <div*/}
                {/*            className="absolute inset-0 bg-no-repeat bg-contain z-1 bg-left-bottom"*/}
                {/*            style={{ backgroundImage: `url('https://b-expression.com/wp-content/uploads/2019/12/led-wall.png')`, backgroundSize: 'cover' }}*/}
                {/*        ></div>*/}
                {/*    </div>*/}
                {/*    <div className="relative flex items-center m-auto w-full xl:max-w-screen-2xl lg:max-w-screen-xl md:max-w-screen-md lg:px-20 text-dark lg:gap-x-20">*/}
                {/*        /!*<div className="h-full">*!/*/}
                {/*        <div className="lg:w-1/2 w-full m-auto sm:py-11 py-1 lg:py-44 my-0 lg:mx-0 sm:my-0 visible z-30 my-8 lg:my-0 flex items-center justify-start px-6 md:px-12 lg:px-0 sm:max-w-screen-lg md:my-6 ml-auto bg-blue-500 bg-opacity-50 h-full flex flex-col-reverse rounded-tr-lg rounded-br-lg" data-aos="fade-right">*/}
                {/*            <div className="h-full">*/}
                {/*                <div className="p-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left" data-aos="zoom-in">*/}
                {/*                    <div className="z-10 w-full pb-4 md:pb-6">*/}
                {/*                        <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">LED Walls - Optimized Performance & Space</h2>*/}
                {/*                        <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>*/}
                {/*                    </div>*/}
                {/*                    <div className="text-base sm:text-lg font-body sm:leading-loose">We partner the world’s pioneers of high resolution LED display technology and we’re deploying their systems in corporate, sports stadia, leisure and retail environments all around Qatar to stunning effect.*/}
                {/*                        The slim styling, high brightness, power efficiency, low heat and absolutely silent operations makes this a first for high resolution LED video walls, digital signage, large meeting rooms, auditoria, broadcast, command and control, front of house, presentation and visualisation and simulation applications.*/}

                {/*                    </div>*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}

                {/*</div>*/}

                {/*  2*/}
                {/*<div className="relative">*/}
                {/*    <div className="absolute inset-0 -z-1">*/}
                {/*        <div*/}
                {/*            className="absolute inset-0 bg-no-repeat bg-contain z-1 bg-left-bottom"*/}
                {/*            style={{ backgroundImage: `url('https://b-expression.com/wp-content/uploads/2019/12/digital-display-mupi-300x300.png')`, backgroundSize: 'cover' }}*/}
                {/*        ></div>*/}
                {/*    </div>*/}

                {/*    <div className="flex items-center justify-between m-auto w-full xl:max-w-screen-2xl lg:max-w-screen-xl md:max-w-screen-md lg:px-20 text-dark lg:gap-x-20">*/}

                {/*        <div className="lg:w-1/2 ">*/}
                {/*            /!*<div className="h-full">*!/*/}
                {/*            /!*    <div className="py-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left">*!/*/}
                {/*            /!*        <div className="z-10 w-full pb-4 md:pb-6">*!/*/}
                {/*            /!*            <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Modular Partitions</h2>*!/*/}
                {/*            /!*            <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>*!/*/}
                {/*            /!*        </div>*!/*/}
                {/*            /!*        <div className="text-base sm:text-lg font-body sm:leading-loose">Modular partition systems provide unlimited design flexibility. Create walls, corners, booths, or complete rooms. Integrate lit and non-lit fabric panels as well as digital screens for an omnichannel display.</div>*!/*/}
                {/*            /!*    </div>*!/*/}
                {/*            /!*</div>*!/*/}
                {/*        </div>*/}
                {/*        /!* Right Side Content *!/*/}
                {/*        <div className="lg:w-1/2  m-auto sm:py-11 py-1 lg:py-44 my-0 lg:mx-0 sm:my-0 visible z-30 my-8 lg:my-0 flex items-center justify-start px-6 md:px-12 lg:px-0 sm:max-w-screen-lg md:my-6 mr-auto bg-red-500 bg-opacity-50 h-full flex flex-col-reverse rounded-tr-lg rounded-br-lg" data-aos="fade-left">*/}
                {/*            <div className="h-full">*/}
                {/*                <div className="p-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left"  data-aos="zoom-in">*/}
                {/*                    <div className="z-10 w-full pb-4 md:pb-6">*/}
                {/*                        <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Mupis & Interactive*/}
                {/*                            Display Products</h2>*/}
                {/*                        <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>*/}
                {/*                    </div>*/}
                {/*                    <div className="text-base sm:text-lg font-body sm:leading-loose"> BrandX provides high quality mupis and interactive screens with industrial quality build and high-tech fully customized content to go with them. These are great for advertisements, instructional material and advertising. Our digital displays and mupis can physically be fully customized with your business brand to emphasize your brand and create a more personal experience for your*/}
                {/*                        customers. We also develop complete graphics and software for these products.</div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}




                {/*3*/}

                {/*<div className="relative  ">*/}
                {/*    <div className="absolute inset-0 -z-1">*/}
                {/*        <div*/}
                {/*            className="absolute inset-0 bg-no-repeat bg-contain z-1 bg-left-bottom"*/}
                {/*            style={{ backgroundImage: `url('https://b-expression.com/wp-content/uploads/2019/12/digital-signage.png')`, backgroundSize: 'cover' }}*/}
                {/*        ></div>*/}
                {/*    </div>*/}
                {/*    <div className="relative flex items-center m-auto w-full xl:max-w-screen-2xl lg:max-w-screen-xl md:max-w-screen-md lg:px-20 text-dark lg:gap-x-20">*/}
                {/*        /!*<div className="h-full">*!/*/}
                {/*        <div className="lg:w-1/2 w-full m-auto sm:py-11 py-1 lg:py-44 my-0 lg:mx-0 sm:my-0 visible z-30 my-8 lg:my-0 flex items-center justify-start px-6 md:px-12 lg:px-0 sm:max-w-screen-lg md:my-6 ml-auto bg-green-700 bg-opacity-50 h-full flex flex-col-reverse rounded-tr-lg rounded-br-lg" data-aos="fade-right">*/}
                {/*            <div className="h-full">*/}
                {/*                <div className="p-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left" data-aos="zoom-in">*/}
                {/*                    <div className="z-10 w-full pb-4 md:pb-6">*/}
                {/*                        <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Digital Signage & Information Board Products</h2>*/}
                {/*                        <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>*/}
                {/*                    </div>*/}
                {/*                    <div className="text-base sm:text-lg font-body sm:leading-loose">BrandX provides the most vibrant and attractive digital signage products in the market along with the most affoardable prices. Our products are built to last and are clearly visible in all lighting situations. Built to last, our special products are completely weather-proof and can be customized to your needs. From scrolling tickers to large info bpards, we are certain*/}
                {/*                        you will find what you didn’t know you were looking for!*/}

                {/*                    </div>*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}

                {/*</div>*/}

                {/*/!*  4*!/*/}
                {/*<div className="relative">*/}
                {/*    <div className="absolute inset-0 -z-1">*/}
                {/*        <div*/}
                {/*            className="absolute inset-0 bg-no-repeat bg-contain z-1 bg-left-bottom"*/}
                {/*            style={{ backgroundImage: `url('https://b-expression.com/wp-content/uploads/2019/12/2.png')`, backgroundSize: 'cover' }}*/}
                {/*        ></div>*/}
                {/*    </div>*/}

                {/*    <div className="flex items-center justify-between m-auto w-full xl:max-w-screen-2xl lg:max-w-screen-xl md:max-w-screen-md lg:px-20 text-dark lg:gap-x-20">*/}

                {/*        <div className="lg:w-1/2 ">*/}
                {/*            /!*<div className="h-full">*!/*/}
                {/*            /!*    <div className="py-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left">*!/*/}
                {/*            /!*        <div className="z-10 w-full pb-4 md:pb-6">*!/*/}
                {/*            /!*            <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Modular Partitions</h2>*!/*/}
                {/*            /!*            <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>*!/*/}
                {/*            /!*        </div>*!/*/}
                {/*            /!*        <div className="text-base sm:text-lg font-body sm:leading-loose">Modular partition systems provide unlimited design flexibility. Create walls, corners, booths, or complete rooms. Integrate lit and non-lit fabric panels as well as digital screens for an omnichannel display.</div>*!/*/}
                {/*            /!*    </div>*!/*/}
                {/*            /!*</div>*!/*/}
                {/*        </div>*/}
                {/*        /!* Right Side Content *!/*/}
                {/*        <div className="lg:w-1/2  m-auto sm:py-11 py-1 lg:py-44 lg:mx-0 sm:my-0 visible z-30 my-8 lg:my-0 items-center justify-start px-6 md:px-12 lg:px-0 sm:max-w-screen-lg md:my-6 mr-auto bg-orange-600 bg-opacity-50 h-full flex flex-col-reverse rounded-tr-lg rounded-br-lg" data-aos="fade-left">*/}
                {/*            <div className="h-full">*/}
                {/*                <div className="p-4 text-center sm:py-0 relative flex flex-col items-start text-white sm:text-left"  data-aos="zoom-in">*/}
                {/*                    <div className="z-10 w-full pb-4 md:pb-6">*/}
                {/*                        <h2 className="text-4xl font-header md:text-6xl z-10 w-full text-white">Marketing Material Design, Print & Production Services</h2>*/}
                {/*                        <span className="w-16 h-0.5 my-4 sm:block z-10 flourish bg-white mx-auto md:mx-0"></span>*/}
                {/*                    </div>*/}
                {/*                    <div className="text-base sm:text-lg font-body sm:leading-loose">Well-designed, professionally printed marketing materials serve as an*/}
                {/*                        effective leave behind tool when approaching a customer. BrandX creates brochures, business cards, sales sheets and more that not*/}
                {/*                        only visually appeal to an audience, but explain the benefits of your products or services and position your brand properly.</div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            {/*</section>*/}

            <Reach_us/>

            <Footer/>

        </div>
    );
}

export default Digital_print;
