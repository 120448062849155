import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./view/home";
import About from "./view/about";
import Blog from "./view/blog";
import Contact from "./view/contactus";
import Services from "./view/services";
import Digital_print from "./components/pages/services_digital_print";
import Events_exhibitions from "./components/pages/services_events_exhibitions";
import Faq from "./components/common/f&q";
import Case_studies from "./components/pages/insights_case_studies";
import Sample from "./components/common/sampl";
import GalleryPage from "./components/common/gallery_page";

// Services Components Imports
import BrandingServices from "./view/services/brandingServices";
import CoperateGifts from "./view/services/coperateGifts";
import DesignServices from "./view/services/designServices";
import DigitalPrinting from "./view/services/digitalPrinting";
import EventManagement from "./view/services/eventManagement";
import ShowroomDisplay from "./view/services/showroomDisplay";
import Signages from "./view/services/signages";
import TradeShowDisplay from "./view/services/tradeShowDisplay";
import VehicleWraps from "./view/services/vehicleWraps";
import WallAndWindowGraphics from "./view/services/wallAndWindowGraphics";

// Our Work Components Imports
import OurWork from "./view/ourwork";
import CeremoniesAndSports from "./view/ourworks/ceremoniesAndSports";
import Cultural from "./view/ourworks/cultural";
import Education from "./view/ourworks/education";
import Entertainment from "./view/ourworks/entertainment";
import Technology from "./view/ourworks/technology";

// Products Components Imports
import Products from "./view/products";
import AdvertisingBoards from "./view/products/advertisingBoards";
import Sineages from "./view/products/signages";
import Flags from "./view/products/flags";

function App() {
  return (
    <div className="App max-w-screen ">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/sample" element={<Sample />} />

          <Route exact path="/FaQ" element={<Faq />} />
          <Route exact path="/case_studies" element={<Case_studies />} />

          <Route exact path="/gallery_page" element={<GalleryPage />} />

          {/*    new*/}

          {/* <Route exact path="/digital_print" element={<Digital_print />} />
                  <Route exact path="/design_services" element={<Services_Design_Serv/>} />
                  <Route exact path="/branding_services" element={<Services_Branding_Ser/>} />
                  <Route exact path="/events_exhibitions" element={<Events_exhibitions/>} />


                  <Route exact path="/vehicle_wraps" element={<Services_Vehicle_Wraps/>} />
                  <Route exact path="/trade_show_display" element={<Services_Trade_Show_Display/>} />
                  <Route exact path="/corporate_gifts" element={<Service_Corporate_Gifts/>} />
                  <Route exact path="/showroom_display" element={<Services_Showroom_Display/>} />
                  <Route exact path="/signages" element={<Services_Signages/>} />
                  <Route exact path="/wall_window_graphics" element={<Services_Wall_And_Window_Graphics/>} /> */}

          {/* Services Routes */}
          <Route exact path="/services/branding-services" element={<BrandingServices />} />
          <Route exact path="/services/corporate-gifts" element={<CoperateGifts />} />
          <Route exact path="/services/design-services" element={<DesignServices />} />
          <Route exact path="/services/digital-printing" element={<DigitalPrinting />} />
          <Route exact path="/services/event-management" element={<EventManagement />} />
          <Route exact path="/services/showroom-display" element={<ShowroomDisplay />} />
          <Route exact path="/services/signages" element={<Signages />} />
          <Route exact path="/services/trade-show-display" element={<TradeShowDisplay />} />
          <Route exact path="/services/vehicle-wraps" element={<VehicleWraps />} />
          <Route exact path="/services/wall-and-window-graphics" element={<WallAndWindowGraphics />} />


          {/*Our Work Routes */}
          <Route exact path="/our-work" element={<OurWork />} />
          <Route exact path="/our-work/ceremonies-and-sports" element={<CeremoniesAndSports />} />
          <Route exact path="/our-work/cultural" element={<Cultural />} />
          <Route exact path="/our-work/education" element={<Education />} />
          <Route exact path="/our-work/entertainment" element={<Entertainment />} />
          <Route exact path="/our-work/technology" element={<Technology />} />

          {/*Products Routes*/}
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/products/advertising-boards" element={<AdvertisingBoards />} />
          <Route exact path="/products/signages" element={<Sineages />} />
          <Route exact path="/products/flags" element={<Flags />} />


        </Routes>
      </Router>
    </div>
  );
}

export default App;
