import React, {useEffect, useRef, useState} from 'react';
import Footer from "../components/common/footer";
import Navbar from "../components/common/navbar";
import HomeAbout from "../components/pages/home-about";

import HomeFaq from "../components/pages/home-FAQ";
import Gallery from "../components/pages/home-project-gallary";

import HomeCarousel from "../components/pages/home-carousel";

import preloader from '../images/pre-loader/pre-loader-brandx-2.gif';
import ReachUs from "../components/common/reach_us";
import HomeClient from "../components/pages/home-client";

// import MetaTags from 'react-meta-tags';
// import {ReactTitle} from 'react-meta-tags';


const Home = () => {
    const homeAboutRef = useRef(null);
    const homeGalleryRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const dataFetch = () => {
            setTimeout(() => {
                setIsLoading(false);
            }, 4000);
        }
        dataFetch();
    })



    return isLoading ? (
        <div className="bg-[#090909]">

            <div className="flex items-center justify-center h-screen">
                <img src={preloader} className="h-[250px] w-auto" alt="Loader"/>
            </div>
        </div>

    ) : (


    <div className="bg-neutral-950 ">


        <Navbar/>
        <div>
            <HomeCarousel homeAboutRef={homeAboutRef}/>
            <HomeAbout ref={homeAboutRef}/>
                <HomeClient/>

                <Gallery/>
                <ReachUs/>
                <HomeFaq/>
            </div>
            <Footer/>
        </div>


    );
}

export default Home;