import React, { useEffect, useRef, useState } from 'react';
import AOS from "aos";
import { InView } from 'react-intersection-observer';

import ImageSlider from '../common/ImageSlider';

import Image1 from "../../images/homepage-carousel/3.jpg";
import Image2 from "../../images/homepage-carousel/4.jpg";
import Image3 from "../../images/homepage-carousel/2.jpg";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";
import '../../css/button.css';

const HomeAbout = React.forwardRef((props, ref) => {

    useEffect(() => {
        AOS.init();
    }, []);

    const images = [
        { url: Image1, gradient: "radial-gradient(at 71.2% 53.7%, hsl(232, 59%, 44%) 0px, transparent 50%),radial-gradient(at 57.5% 36.2%, hsl(0, 0%, 0%) 0px, transparent 50%)" },
        { url: Image2, gradient: "radial-gradient(at 76% 1%, rgb(48, 26, 3) 0px, transparent 50%), radial-gradient(at 59.5% 52.3%, rgb(75, 79, 88) 0px, transparent 50%)" },
        { url: Image3, gradient: "radial-gradient(at 89.4% 33.1%, hsl(0, 0%, 0%) 0px, transparent 50%),radial-gradient(at 66.7% 54.2%, hsl(321, 100%, 29%) 0px, transparent 50%" },
        // Add more image URLs and gradient colors as needed
    ];

    const [currentGradient, setCurrentGradient] = useState(images[0].gradient);

    const handleGradientChange = (newGradient) => {
        setCurrentGradient(newGradient);
    };

    const clipPathStyle = {
        WebkitClipPath: 'polygon(0 10%, 100% 0, 100% 100%, 0 90%)',
        clipPath: 'polygon(0 10%, 100% 0, 100% 100%, 0 90%)',
    };

    return (
        <div ref={ref} className="bg-neutral-950 min-h-screen" style={{ background: currentGradient }}>
            <section className="py-48 mx-auto max-w-screen-xl pb-30 px-4 items-center lg:flex md:px-8">

                <div className="space-y-4 flex-1 sm:text-center lg:text-left">
                    <span className={'text-pantone hidden lg:block uppercase font-bold text-sm'}>Who We Are</span>
                    <InView>
                        {({ inView, ref }) => (
                            <div ref={ref} className="text-neutral-400 text-xl font-black custom-type-animation">
                                {inView && (
                                    <TypeAnimation
                                        sequence={[
                                            'About Brand Expression',
                                            1000,
                                        ]}
                                        speed={50}
                                        repeat={0}
                                        cursor={false}
                                        style={{ fontSize: '2em' }}
                                    />
                                )}
                            </div>
                        )}
                    </InView>
                    <p className="text-gray-300 max-w-xl leading-relaxed sm:mx-auto lg:ml-0" data-aos="fade-up">
                        At The Brand Expression, we are your premier destination for all your printing and installation
                        needs. With a staggering 9000 sqm printing capacity and a diverse range of capabilities, we bring
                        your visions to life with unparalleled precision and quality.
                        <br/>
                        Our state-of-the-art facility boasts cutting-edge technology, enabling us to produce a wide array
                        of materials including poly woven fabric, vinyl stickers, banner ex, and high-quality UV roll to roll
                        prints. Whether you need vibrant banners, eye-catching stickers, or intricate signage, we have the
                        expertise and capacity to deliver beyond expectations.
                        <br/>
                        {/*Not stopping there, our commitment to excellence extends beyond the printing press. We take*/}
                        {/*pride in our highly talented project management team, ensuring seamless coordination and*/}
                        {/*execution of every project. Backed by years of experience and a keen eye for detail, our team*/}
                        {/*ensures your project is delivered on time and within budget.*/}
                        {/*<br/>*/}
                        {/*Furthermore, our skilled and experienced installation team stands ready to bring your designs to*/}
                        {/*life in the physical world. With an in-house installation capacity of around 50 professionals,*/}
                        {/*backed by an extendable network of 250 installers, we handle projects of any scale with utmost*/}
                        {/*efficiency and expertise.*/}
                        {/*<br/>*/}
                        {/*At The Brand Expression, customer satisfaction is our top priority. Whether you need a quote,*/}
                        {/*have a question, or seek advice on a project, our dedicated team is here to assist you every step*/}
                        {/*of the way. Give us a call today and let us turn your ideas into reality!*/}
                    </p>
                    <div className="mt-3 w-12/12 flex flex-end justify-start mb-8">
                        <Link to={`/about-us`}>
                            {/*<div className="text-gray-300 text-md sm:absolute relative flex items-center justify-center z-10 font-bold trapezoid-about h-[120px] w-[240px] cursor-pointer ">*/}
                            {/*    /!* Text *!/*/}
                            {/*    <span className="text-neutral-200 ">See More</span>*/}
                            {/*</div>*/}

                            <button className="text-gray-300 text-lg flex items-center rounded-full justify-center bg-pantone/70 hover:bg-transparent hover:text-pantone font-bold h-[40px] w-[150px] cursor-pointer hover:border border-pantone">
                                {/* Text */}
                                Learn More
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="flex-1 text-center mt-4 lg:mt-0 lg:ml-3 " data-aos="zoom-in" style={clipPathStyle}>
                    <ImageSlider images={images} onGradientChange={handleGradientChange} />
                </div>
            </section>
        </div>



    );
});

export default HomeAbout;