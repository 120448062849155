import React, {useEffect} from "react"
import AOS from "aos";
import Navbar from "../components/common/navbar";
import {Link} from "react-router-dom";
import Reach_us from "../components/common/reach_us";
import Footer from "../components/common/footer";

import SportsImage from "../images/ourWorks/Ceremonies.webp";
import CultureImage from "../images/ourWorks/Cultural.webp";
import EducationImage from "../images/ourWorks/Education.webp";
import EntertainmentImage from "../images/ourWorks/Education.webp";
import TechnologyImage from "../images/ourWorks/Technology.webp";

const OurWork = () => {
    const gradientStyle = {
        background: "bg-neutral-950",
        // backgroundImage: "radial-gradient(at 76.0% 1.0%, hsl(30, 87%, 10%) 0px, transparent 50%), radial-gradient(at 59.5% 52.3%, hsl(222, 8%, 32%) 0px, transparent 50%)",
        height: 'auto'
        // Add other styles if needed
    };
    const boxStyle = {
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    };

    const hoverStyle = {
        transform: 'scale(1.05)', // Increase size on hover
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)', // Add box shadow on hover
    };



    useEffect(() => {
        AOS.init({
            duration: 1500, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);
    return (

        <div className="bg-neutral-950">


            <Navbar/>


            <div className="  mx-auto ">
                <section className=" ">
                    <div
                        className="relative  h-[400px] lg:h-[500px] w-screen overflow-hidden bg-cover bg-no-repeat bg-[url('https://b-expression.com/wp-content/uploads/2019/12/qatar.jpg')]">
                        <div
                            className="absolute max-h-[400px] lg:max-h-[600px] inset-0 backdrop-blur-none bg-black/60 flex justify-center items-center">
                            <div className="text-center">
                                <h1 className="text-neutral-200 text-3xl font-black uppercase">
                                    OUR WORK
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div className="container px-1 md:px-12">
                        <div
                            className="block h-full rounded-lg bg-[hsla(0,0%,100%,0.8)] px-4 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,20%,0.3)] dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] ">
                            <section className="w-full mx-auto py-10 flex flex-col gap-5  dark:text-white">


                                <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
                                    <div className="flex flex-col sm:flex-row justify-center gap-4">
                                        <div
                                            data-aos={'fade-up'}
                                            className="flex flex-col items-center justify-center aspect-auto p-4 sm:p-8  rounded-3xl bg-black/35 backdrop-blur-lg shadow-black/10 shadow-none m-2 flex-1 max-w-1/2">
                                            <h2 className="text-lg sm:text-lg font-extrabold text-white mb-4 uppercase">Ceremonies &
                                                Sports</h2>
                                            <img
                                                className={'rounded-lg'}
                                                src={SportsImage} alt={"Ceremonies and Sports"}/>
                                            {/*<p className="text-lg sm:text-xl text-center mb-8 mt-4 text-gray-400">*/}
                                            {/*    <span className="text-3xl sm:text-4xl font-bold text-white">2</span>Projects*/}
                                            {/*</p>*/}
                                            <div>
                                            <ul className="list-none list-inside mb-6 text-center text-gray-300">
                                                <li className="font-bold text-orange-600 uppercase mt-2">OUR WORK</li>
                                                {/*<li>some sub info</li>*/}
                                            </ul>
                                            <Link
                                                className="lemonsqueezy-button relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-white before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                                to="/ourwork/ceremonies-and-sports"><span
                                                className="relative text-sm font-semibold text-black">View Ceremonies & Sports</span></Link>
                                            </div>
                                        </div>
                                        <div
                                            data-aos={'fade-up'}
                                            className="flex flex-col items-center justify-center aspect-auto p-4 sm:p-8  rounded-3xl bg-black/35 backdrop-blur-lg shadow-black/10 shadow-none m-2 flex-1 max-w-1/2">
                                            <h2 className="text-lg sm:text-lg font-extrabold text-white mb-4 uppercase">Cultural</h2>
                                            <img
                                                src={CultureImage} alt={'Culture'}
                                                className={'max-h-[256px] w-[540px] rounded-lg'} />
                                            {/*<p className="text-lg sm:text-xl text-center mb-8 mt-4 text-gray-400">*/}
                                            {/*    <span className="text-3xl sm:text-4xl font-bold text-white">3</span>Projects*/}
                                            {/*</p>*/}
                                            <div>
                                                <ul className="list-none list-inside mb-6 text-center text-gray-300">
                                                    <li className="font-bold text-orange-600 uppercase mt-2">OUR WORK</li>
                                                {/*<li>some sub info</li>*/}
                                            </ul>
                                            <Link
                                                className="lemonsqueezy-button relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-white before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                                to="/ourwork/cultural"><span
                                                className="relative text-sm font-semibold text-black">Check Cultural</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:flex-row justify-center gap-4">
                                        <div
                                            data-aos={'fade-right'}
                                            className="flex flex-col items-center aspect-auto p-4 sm:p-8  rounded-3xl bg-black/35 backdrop-blur-lg shadow-black/10 shadow-none m-2 flex-1 max-w-md">
                                            <h2 className="text-lg sm:text-lg font-extrabold text-white mb-4 uppercase">Education</h2>
                                            <img src={EducationImage} alt={'education'} className={'max-h-[186px] w-[330px] rounded-lg'} />
                                            {/*<p className="text-lg sm:text-xl text-center mb-8 mt-4 text-gray-400">*/}
                                            {/*    <span className="text-3xl sm:text-4xl font-bold text-white">2</span>Projects*/}
                                            {/*</p>*/}
                                            <div>
                                                <ul className="list-none list-inside mb-6 text-center text-gray-300">
                                                    <li className="font-bold text-orange-600 uppercase mt-2">OUR WORK</li>
                                                {/*<li>some sub info</li>*/}
                                            </ul>
                                            <Link
                                                className="lemonsqueezy-button relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-white before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                                to="/ourwork/education"><span
                                                className="relative text-sm font-semibold text-black">Check Education</span></Link>
                                            </div>
                                        </div>
                                        <div
                                            data-aos={'fade-up'}
                                            className="flex flex-col items-center aspect-auto p-4 sm:p-8  rounded-3xl bg-black/35 backdrop-blur-lg shadow-black/10 shadow-none m-2 flex-1 max-w-md">
                                            <h2 className="text-lg sm:text-lg font-extrabold text-white mb-4 uppercase">Entertainment</h2>
                                            <img src={EntertainmentImage} alt={'EnImage'} className={'max-h-[186px] w-[330px] rounded-lg'}/>
                                            {/*<p className="text-lg sm:text-xl text-center mb-8 mt-4 text-gray-400">*/}
                                            {/*    <span className="text-3xl sm:text-4xl font-bold text-white">2</span>Projects*/}
                                            {/*</p>*/}
                                            <div>
                                                <ul className="list-none list-inside mb-6 text-center text-gray-300">
                                                    <li className="font-bold text-orange-600 uppercase mt-2">OUR WORK</li>
                                                {/*<li>some sub info</li>*/}
                                            </ul>
                                            <Link
                                                className="lemonsqueezy-button relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-white before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                                to="/ourwork/entertainment"><span
                                                className="relative text-sm font-semibold text-black">Check Entertainment</span></Link>
                                            </div>
                                        </div>
                                        <div
                                            data-aos={'fade-left'}
                                            className="flex flex-col items-center aspect-auto p-4 sm:p-8  rounded-3xl bg-black/35 backdrop-blur-lg shadow-black/10 shadow-none m-2 flex-1 max-w-md">
                                            <h2 className="text-lg sm:text-lg font-extrabold text-white mb-4 uppercase">Technology</h2>
                                            <img src={TechnologyImage} alt={'TechImage'} className={'max-h-[186px] w-[330px] rounded-lg'}/>
                                            {/*<p className="text-lg sm:text-xl text-center mb-8 mt-4 text-gray-400">*/}
                                            {/*    <span className="text-3xl sm:text-4xl font-bold text-white">2</span>Projects*/}
                                            {/*</p>*/}
                                            <div>
                                                <ul className="list-none list-inside mb-6 text-center text-gray-300">
                                                    <li className="font-bold text-orange-600 uppercase mt-2">OUR WORK</li>
                                                {/*<li>some sub info</li>*/}
                                            </ul>
                                            <Link
                                                className="lemonsqueezy-button relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-white before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                                                to="/ourwork/technology"><span
                                                className="relative text-sm font-semibold text-black">Check Technology</span></Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>







                            </section>

                        </div>
                    </div>
                </section>
            </div>


            <Reach_us/>


            <Footer/>


        </div>

    );

}

export default OurWork;