import React from 'react';
import Navbar from "./navbar";
import Breadcrumb from "./breadcrumb";
import Footer from "./footer";
import {HomeIcon} from "@heroicons/react/16/solid";
import image1 from '../../images/homepage-carousel/1.jpg';
import image2 from '../../images/homepage-carousel/2.jpg';
import image3 from '../../images/homepage-carousel/3.jpg';
import image4 from '../../images/homepage-carousel/4.jpg';






const breadcrumbItems = [
    { label: <HomeIcon className="h-4 w-5" />, url: '/' },
    { label: 'Reach Us', url: '/contact-us' },
];


const GalleryPage = () => {
    return (
        <div>


            <div className="bg-neutral-950">
                <Navbar/>
                <div className="mx-auto bg-neutral-950">
                    <section className=" pb-10">
                        <div className="relative h-[400px] overflow-hidden bg-cover bg-[50%] bg-no-repeat bg-[url('https://b-expression.com/wp-content/uploads/2022/07/01.jpg')]">
                            <div className="absolute inset-0 backdrop-blur-none bg-black/60 flex justify-center items-center">
                                <div className="text-center">

                                    <h3 className="font-semibold uppercase p-5 text-center " style={{
                                        fontSize: '48px',
                                        // background: 'radial-gradient(circle, rgba(22,162,227,1) 0%, rgba(196,70,238,1) 82%)',
                                        WebkitBackgroundClip: 'text',
                                        backgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        textFillColor: 'transparent',
                                        WebkitTextStroke: '2px #e17426',
                                        textStroke: '2px #e17426'
                                    }}>
                                        Gallery
                                    </h3>

                                </div>
                            </div>
                        </div>

                        <div className="container md:px-12 max-w-[1480px]">
                            <div className="pt-3 block bg-gradient-to-b from-neutral-950/60 to-neutral-950 px-4 -mt-[100px] backdrop-blur-[30px] rounded-lg">

                                <div className="mt-1">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>



                                <div className=" mx-auto">





                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                                        {/*<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">*/}

                                        <div className="relative col-span-2">
                                            <img src={image1} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>
                                        <div className="relative  row-span-2">
                                            <img src={image2} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>
                                        <div className="relative">
                                            <img src={image3} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>
                                        <div className="relative  row-span-2">
                                            <img src={image4} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>

                                        <div className="relative ">
                                            <img src={image2} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>



                                        <div className="relative  row-span-2 ">
                                            <img src={image2} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>
                                        <div className="col-span-2 relative">
                                            <img src={image1} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>

                                        <div className="relative ">
                                            <img src={image2} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>






                                        <div className="relative  ">
                                            <img src={image4} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>
                                        <div className="col-span-2 relative">
                                            <img src={image3} alt="Gallery item" className="w-full h-full object-cover rounded" />
                                        </div>

                                        {/*<div className="relative">*/}
                                        {/*    <img src={image2} alt="Gallery item" className="w-full h-full object-cover rounded" />*/}
                                        {/*</div>*/}
                                    </div>

                                </div>

                            </div>


                        </div>


                        {/*<div className="h-[3vh] min-w-full w-full flex flex-end justify-end">*/}
                        {/*    <div className="w-6/12 h-full min-h-full">*/}
                        {/*        <div className="bg-pantone h-full w-full min-w-full min-h-full"></div>*/}
                        {/*    </div>*/}

                        {/*</div>*/}




                    </section>
                </div>




                <Footer/>

            </div>

        </div>
    );
};

export default GalleryPage;